.bitrix-compare {
	padding-top: 50px;
	padding-bottom: 130px;

	&__title {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 26px;
		color: #333333;
		text-align: center;
	}
	&__btn {
		width: 313px;
		margin: 0 auto;
		margin-top: 50px;
	}
}