.descrip-hero {
	background: #6485ea;
	padding-top: 100px;
	padding-bottom: 80px;
	&__title {
		.main-titling;
		margin-bottom: 40px;
	}
	&__desc {
		color: #fff;
	}
	&__bottom-line {
		display: flex;
		align-items: center;
		margin-top: 50px;
	}
	&__btn {
		.btn-white;
		display: block;
		padding: 0 50px;
	}

	&__price {
		font-family: "Roboto-Bold", sans-serif;
		color: #fff;
		margin-left: 60px;
	}
	
}

.description-content-wrapper {
	background: #e8eaee;
	padding-top: 150px;
	padding-bottom: 100px;
	box-shadow: 1.763px 2.427px 15px 0px rgba(0, 0, 0, 0.1);
}
