.eset-innovations {
	padding-top: 100px;
	padding-bottom: 100px;
	background: #fbfbfb;
	&__title {
		.eset-section-titling;
		text-align: center;
		margin-bottom: 40px;
	}
	&__description {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		color: #424c56;
		text-align: center;
	}
	&__items-holder {
		margin-top: 80px;
		display: flex;

	}
	&__items-row {
		display: flex;
		justify-content: center;
		flex: 0 1 auto;
		margin-left: -30px;
		margin-right: -30px;
		&:first-child {
			margin-right: 60px;
		}
	}
}

.innovation-item {
	flex: 1 0 220px;
	margin-left: 30px;
	margin-right: 30px;
	margin-bottom: 50px;


	&__icon {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}
	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 20px;
		color: #424c56;
		line-height: 1.2;
		margin-top: 25px;
		margin-bottom: 25px;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 17px;
		color: #424c56;
		line-height: 1.47;
	}
}



@media (max-width: 1440px) {
	.eset-innovations {
		&__items-holder {
			flex-direction: column;
		}

		&__items-row {
			justify-content: flex-start;
			align-items: stretch;
		}
	}
	.innovation-item {
		flex-grow: 0;
		flex-basis: 300px
	}
}

@media (max-width: 1200px) {
	.eset-innovations {
		&__items-holder {
			flex-direction: column;
		}
		&__items-row {
			flex-direction: column;
		}
	}
	.innovation-item {
		margin-bottom: 30px;
	}
}

@media (max-width: 992px) {
	.eset-innovations {
		&__items-row {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.innovation-item {
		margin-right: 0;
		margin-left: 0;
	}
}

