.main-abilities {
	padding: 95px 0;
	&__title {
		.section-titling;
		margin-bottom: 65px;
	}
}

.ability {
	display: flex;
	margin-bottom: 70px;

	

	&__icon {
		flex: 1 0 auto;
		width: 150px;
		height: 150px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		margin-right: 45px;
	}
	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 20px;
		margin-bottom: 25px;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 14px;
		line-height: 1.5;
		margin-bottom: 25px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}


.enterprise-block {
	&__title {
		.section-titling;
		margin-bottom: 55px;
	}
	&__links-holder {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
	&__link {
		display: block;
		position: relative;
		font-family: "Roboto-Bold", sans-serif;
		font-size: 20px;
		letter-spacing: 2.5px;
		padding-bottom: 40px;
		border-bottom: 2px solid #000;
		color: #000;
		overflow: hidden;
		transition: padding-right .4s ease-out;
		
		&:hover,
		&:active,
		&:focus, {
			color: #4267af;
			border-color: #4267af;
			padding-right: 60px;

			.abs-arrow {
				background-color: #4267af;

				&:after {
					border-color: #4267af;
				}
			}
		}

		.abs-arrow {
			&--fix113 {
				left: 113px;
			}
			&--fix138 {
				left: 138px;
			}
			display: block;
			width: 35px;
			height: 4px;
			background: #000;
			position: absolute;
			margin-left: 10px;
			top: 0;
			margin-top: 14px;
			transition: bottom .4s ease-out;
			&:after {
				content: '';
				position: absolute;
				right: 0;
				top: 50%;
				width: 14px;
				height: 14px;
				border-bottom: 3px solid #000;
				border-right: 3px solid #000;
				transform: rotate(-45deg) translate(30%, -42%);
			}
		}
	}
}


@media (max-width: 460px) {
	.enterprise-block {
		&__links-holder {
			display: block;
		}

		&__link {
			display: block;
			margin-bottom: 30px;
			padding-bottom: 15px;
			border-bottom: 0;
		}
	}
}


@media (max-width: 580px) {
	.ability {
		display: block;
		&__icon {
			float: left;
			width: 100px;
			height: 100px;
			margin-right: 40px;
			margin-bottom: 20px;
		}
	}
}