.complex-solutions {
	padding-top: 130px;
	margin-bottom: 45px;

	&__title {
		.eset-section-titling;
		text-align: center;
	}
	&__btn {
		border-radius: 0;
		width: 100%;
		margin-top: 70px;
		text-transform: uppercase;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	&__item-holder {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		margin-left: -50px;
		margin-right: -50px;
		margin-top: 50px;
		margin-bottom: 150px;
	}

	&__bottom-text {
		font-family: "Roboto-Italic", sans-serif;
		font-size: 16px;
		color: #424c56;
		text-align: center;
	}

}


.complex-solution-item {
	flex: 1 0 0;
	margin-left: 50px;
	margin-right: 50px;

	display: flex;
	flex-direction: column;
	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 30px;
		color: #424c56;
		margin-bottom: 55px;
	}
	&__list {
		margin-top: auto;
		margin-bottom: 40px;
	}
	&__list-item {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		color: #424c56;
		padding-left: 40px;
		position: relative;


		&:before {
			content: '';
			width: 20px;
			height: 20px;
			position: absolute;
			left: 0;
			top: 3px;
			background-image: url(../img/eset-icons/check-green.png);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
		}

		&.canceled {
			color: #dcdcdc;
			&:before {
				background-image: url(../img/eset-icons/cross-icon.png);
			}
		}
	}
	&__try-btn {
		margin-top: 10px;
	}
}

@media (max-width: 1250px) {
	.complex-solutions {
		&__item-holder {
			margin-left: -20px;
			margin-right: -20px;
		}
	}

	.complex-solution-item {
		margin-left: 20px;
		margin-right: 20px;
	}
}


@media (max-width: 1055px) {
	.complex-solutions {
		&__item-holder {
			display: block;
			margin-left: 0;
			margin-right: 0;
		}
	}

	.complex-solution-item {
		margin-bottom: 50px;
		margin-left: 0;
		margin-right: 0;
		display: block;
		&__title {
			margin-bottom: 25px;
		}
		&__details-btn {
			width: 50%;
		}
		&__try-btn {
			width: 50%;
		}
	}
}

@media (max-width: 550px) {
	.complex-solution-item {
		&__details-btn, &__try-btn {
			width: 100%;
		}
	}
}