.stop-support {
	padding-top: 160px;
	padding-bottom: 135px;
	background-image: url(../img/bg/folder-bg.jpg);
	background-repeat: no-repeat;
	background-position: top right;
	background-size: 50%;

	&__title {
		.section-titling;
		line-height: 1.4;
		margin-bottom: 25px;
	}

	&__text {
		font-family: "Roboto-Regular", sans-serif;
		margin-bottom: 30px;
		text-indent: 40px;
		text-align: justify;
	}
}


@media (max-width: 572px) {
	.stop-support {
		padding: 70px 0;
	}
}

@media (max-width: 375px) {
	.stop-support {
		&__title {
			line-height: 1.2;
			font-size: 20px;
		}
	}
}