.about-hero {
	background: #6485ea;
	padding-top: 50px;
	padding-bottom: 100px;
	background-image: url(../img/bg/about-hero-bg.png);
	background-repeat: no-repeat;
	background-size: 50%;
	background-position: bottom right;

	&__content {}
	&__title {
		.main-titling;
	}
	&__text {
		color: #fff;
		margin-top: 50px;
		font-family: "Roboto-Regular", sans-serif;
	}
}


@media (max-width: 992px) {
	.about-hero {
		background-size: 70%;
	}
}

@media (max-width: 768px) {
	.about-hero {
		background-image: none;
	}
}