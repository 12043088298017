body {
	font-family: "Roboto-Light", sans-serif;
	color: #262626;
	font-size: 18px;
	line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

a {
	text-decoration: none;
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
}

ul {
	padding: 0;
	list-style-type: none;
	margin-bottom: 0;
}

.main-titling {
	font-family: "Roboto-Black", sans-serif;
	font-size: 40px;
	line-height: 1.5;
	color: #fff;
}

.section-titling {
	font-family: "Roboto-Bold", sans-serif;
	font-size: 26px;
	line-height: 2.3;
}

.main-btn {
	font-family: "Roboto-Bold", sans-serif;
	font-size: 14px;
	line-height: 4.28;
	padding-left: 30px;
	padding-right: 30px;
	border-radius: 50px;
	text-align: center;
	
}

.btn-yellow {
	display: block;
	background: #f8e61e;
	font-family: "Roboto-Bold", sans-serif;
	font-size: 14px;
	text-align: center;
	color: #000;
	

	&:hover,
	&:active,
	&:focus, {
		background: #6485ea;
		color: #fff;
	}
}

.btn-white {
	color: @text-accent-blue;
	background: #fff;
	padding-top: 20px;
	padding-bottom: 20px;
	border: 3px solid transparent;
	&:hover,
	&:active,
	&:focus {
		border: 3px solid #f8e114;
		background: #f8e114;
		color: #262626;
		text-decoration: none;
	}
}

.btn-blue-t {
	color: #fff;
	padding-top: 20px;
	padding-bottom: 20px;
	border: 3px solid #fff;
	&:hover,
	&:active,
	&:focus {
		border: 3px solid #f8e114;
		color: #f8e114;
	}
}

.btn-blue-b {
	color: #fff;
	padding-top: 20px;
	padding-bottom: 20px;
	background: @b-accent-blue;
	border: 3px solid transparent;
	&:hover,
	&:active,
	&:focus {
		background: #f8e114;
		color: #262626;
	}
}


.eset-title {
	font-family: "Roboto-Bold", sans-serif;
	font-size: 50px;
	line-height: 1.2;
	color: #fff;
	text-align: center;
}

.eset-section-titling {
	font-family: "Roboto-Bold", sans-serif;
	font-size: 35px;
	line-height: 1.43;
	color: #424c56;
}



.eset-btn {
	font-family: "Roboto-Regular", sans-serif;
	font-size: 20px;
	color: #fff;
	display: block;
	padding-top: 20px;
	padding-bottom: 20px;
	border-radius: 3px;
	text-align: center;
	cursor: pointer;

	&--light-green {
		background: #8ac649;

		&:hover,
		&:active,
		&:focus {
			background: darken(#8ac649, 10%);
			color: #fff;
		}
	}

	&--dark-green {
		background: #0097a2;
		&:hover,
		&:active,
		&:focus {
			background: darken(#0097a2, 10%);
			color: #fff;
		}
	}
	&--white {
		background: #fff;
		color: #00adb6;
		border-radius: 0;
		&:hover,
		&:active,
		&:focus {
			background: #8ac649;
			color: #fff;
		}
	}

	&--grey {
		background: #dcdcdc;
		color: #424c56;
		&:hover,
		&:active,
		&:focus {
			background: darken(#dcdcdc, 10%);
			color: #424c56;
		}
	}
}

.h100-per {
	height: 100%;
}


.bitrix-titling {
	font-family: "Roboto-Bold", sans-serif;
	font-size: 40px;
	color: #fff;
	line-height: 1.5;
	text-align: center;
}

.bitrix-section-titling {
	font-family: "Roboto-Bold", sans-serif;
	font-size: 26px;
	color: #333333;
}

.bitrix-btn {
	display: block;
	padding-top: 20px;
	padding-bottom: 20px;
	border-radius: 50px;
	background: #b5da2e;
	text-align: center;
	text-transform: uppercase;
	font-family: "Roboto-Regular", sans-serif;
	font-size: 14px;
	color: #fff;
	

	&:hover,
	&:active,
	&:focus {
		color: #fff;
		background: darken(#b5da2e, 10%);
	}
}


.r {
	border: 1px solid red;
}



.testblock {
	height: 500px;
	width: 100%;
}