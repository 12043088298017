.eset-checks {
	padding: 30px 0;

	&__item {
		position: relative;
		flex: 1 0 150px;
		margin: 0 45px;
		padding-left: 40px;

		&:before {
			content: '';
			width: 25px;
			height: 20px;
			position: absolute;
			top: 0;
			left: 0;
			background-image: url(../img/eset-icons/check-green.png);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
		}
	}
}


@media (max-width: 1200px) {
	.eset-checks {
		&__item {
			margin-bottom: 20px;
		}
	}
}