.eset-mdb-avards {
	padding: 120px 0;
	background: #fbfbfb;

	&__wrap {
		display: flex;
	}
	&__avards-holder {
		flex: 4 1 50%;
		display: flex;
		justify-content: space-between;

	}
	&__content {
		flex: 2 1 50%;
		margin-left: 70px;
	}
	&__title {
		.eset-section-titling;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		color: #424c56;
		margin-top: 45px;
	}
}

.eset-mdb-avard {
	margin: 0 35px;
	&__icon {
		width: 320px;
		height: 265px;
		background-repeat: no-repeat;
		background-size: 80%;
		background-position: center center;
	}
	&__descrip {
		margin-top: 45px;
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		line-height: 1.5;
		color: #424c56;
	}
}


@media (max-width: 1200px) {
	.eset-mdb-avards {
		&__wrap {
			flex-direction: column;
		}
		&__avards-holder {
			order: 2;
			margin-top: 50px;
		}
		&__content {
			order: 1;
			margin-left: 0;
		}
	}

	.eset-mdb-avard {
		margin-left: 0;

		&__icon {
			float: left;
			background-position: center left;
		}
	}
}


@media (max-width: 768px) {
	.eset-mdb-avards {
		&__avards-holder {
			flex-direction: column;
		}
	}

	.eset-mdb-avard {

		&__icon {
			width: 150px;
			height: 130px;
			background-position: top left;
		}

		&__descrip {
			margin-top: 0;
		}
	}
}

@media (max-width: 480px) {
	.eset-mdb-avard {
		margin-right: 0;
		margin-left: 0;
		&__icon {
			width: 200px;
			height: 180px;
			margin-right: 20px;
			float: none;
			margin-left: auto;
			margin-right: auto;
		}
	}
}