.eset-smb-avards {
	padding: 120px 0;
	background: #fbfbfb;

	&__wrap {
		display: flex;
	}
	&__avards-holder {
		flex: 4 1 70%;
		display: flex;
		justify-content: space-between;
	}
	&__content {
		flex: 2 1 30%;
		margin-left: 70px;
	}
	&__title {
		.eset-section-titling;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		color: #424c56;
		margin-top: 45px;
	}
}

.eset-smb-avard {
	margin: 0 35px;
	&__icon {
		width: 90px;
		height: 90px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
	&__descrip {
		margin-top: 45px;
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		line-height: 1.5;
		color: #acafb3;
	}
}


@media (max-width: 1200px) {
	.eset-smb-avards {
		&__wrap {
			flex-direction: column;
		}
		&__avards-holder {
			order: 2;
			margin-top: 50px;
		}
		&__content {
			order: 1;
			margin-left: 0;
		}
	}
}


@media (max-width: 768px) {
	.eset-smb-avards {
		&__avards-holder {
			flex-direction: column;
		}
	}

	.eset-smb-avard {
		display: flex;
		margin-bottom: 30px;

		&__icon {
			flex-shrink: 0;
			float: left;
			margin-right: 40px;
		}

		&__descrip {
			margin-top: 0;
		}
	}
}

@media (max-width: 480px) {
	.eset-smb-avard {
		margin-right: 0;
		margin-left: 0;
		&__icon {
			margin-right: 20px;
		}
	}
}