.about-partners {
	padding-top: 75px;
	padding-bottom: 95px;

	&__title {
		.section-titling;
		text-align: center;
		margin-bottom: 50px;
	}

	&__content-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 -50px;
		margin-bottom: 90px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	.partner-link {
		flex: 1 1 auto;
		margin: 0 50px;
		display: block;
	}

	.about-partner {
		width: 100%;
		height: auto;
		-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
		filter: grayscale(100%);
		opacity: .3;

		

		&:hover {
			filter: none;
			opacity: 1;
		}
	}
	
}


@media (max-width: 1270px) {
	.about-partners {
		&__content-row {
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 40px;
			&:last-child {
				margin-bottom: 0;
			}
		}

		.partner-link {
			flex-grow: 0;
			margin-bottom: 40px;
		}
	}
}

@media (max-width: 860px) {
	.about-partners {
		&__content-row {
			margin-left: 0;
			margin-right: 0;
			&:last-child {
			}
		}

		.partner-link {
			flex-grow: 0;
			flex-shrink: 1;
		}
	}
}