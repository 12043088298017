/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('../fonts/Roboto/Roboto-Light.eot');
  src: url('../fonts/Roboto/Roboto-Light.eot#iefix') format('embedded-opentype'), url('../fonts/Roboto/Roboto-Light.woff') format('woff'), url('../fonts/Roboto/Roboto-Light.ttf') format('truetype'), url('../fonts/Roboto/Roboto-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto/Roboto-Regular.eot');
  src: url('../fonts/Roboto/Roboto-Regular.eot#iefix') format('embedded-opentype'), url('../fonts/Roboto/Roboto-Regular.woff') format('woff'), url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype'), url('../fonts/Roboto/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('../fonts/Roboto/Roboto-Bold.eot');
  src: url('../fonts/Roboto/Roboto-Bold.eot#iefix') format('embedded-opentype'), url('../fonts/Roboto/Roboto-Bold.woff') format('woff'), url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype'), url('../fonts/Roboto/Roboto-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Black';
  src: url('../fonts/Roboto/Roboto-Black.eot');
  src: url('../fonts/Roboto/Roboto-Black.eot#iefix') format('embedded-opentype'), url('../fonts/Roboto/Roboto-Black.woff') format('woff'), url('../fonts/Roboto/Roboto-Black.ttf') format('truetype'), url('../fonts/Roboto/Roboto-Black.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Italic';
  src: url('../fonts/Roboto/Roboto-Italic.eot');
  src: url('../fonts/Roboto/Roboto-Italic.eot#iefix') format('embedded-opentype'), url('../fonts/Roboto/Roboto-Italic.woff') format('woff'), url('../fonts/Roboto/Roboto-Italic.ttf') format('truetype'), url('../fonts/Roboto/Roboto-Italic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@media (min-width: 1600px) {
  .container {
    width: 1500px;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 1350px;
  }
}
@media (min-width: 1536px) {
  .container {
    width: 1400px;
  }
}
body {
  font-family: "Roboto-Light", sans-serif;
  color: #262626;
  font-size: 18px;
  line-height: 1.5;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
a {
  text-decoration: none;
}
a:hover,
a:active,
a:focus {
  text-decoration: none;
}
ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.main-titling {
  font-family: "Roboto-Black", sans-serif;
  font-size: 40px;
  line-height: 1.5;
  color: #fff;
}
.section-titling {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
}
.main-btn {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 14px;
  line-height: 4.28;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 50px;
  text-align: center;
}
.btn-yellow {
  display: block;
  background: #f8e61e;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 14px;
  text-align: center;
  color: #000;
}
.btn-yellow:hover,
.btn-yellow:active,
.btn-yellow:focus {
  background: #6485ea;
  color: #fff;
}
.btn-white {
  color: #6b8aea;
  background: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 3px solid transparent;
}
.btn-white:hover,
.btn-white:active,
.btn-white:focus {
  border: 3px solid #f8e114;
  background: #f8e114;
  color: #262626;
  text-decoration: none;
}
.btn-blue-t {
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 3px solid #fff;
}
.btn-blue-t:hover,
.btn-blue-t:active,
.btn-blue-t:focus {
  border: 3px solid #f8e114;
  color: #f8e114;
}
.btn-blue-b {
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #8fa7ef;
  border: 3px solid transparent;
}
.btn-blue-b:hover,
.btn-blue-b:active,
.btn-blue-b:focus {
  background: #f8e114;
  color: #262626;
}
.eset-title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 50px;
  line-height: 1.2;
  color: #fff;
  text-align: center;
}
.eset-section-titling {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 35px;
  line-height: 1.43;
  color: #424c56;
}
.eset-btn {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  color: #fff;
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
}
.eset-btn--light-green {
  background: #8ac649;
}
.eset-btn--light-green:hover,
.eset-btn--light-green:active,
.eset-btn--light-green:focus {
  background: #70a834;
  color: #fff;
}
.eset-btn--dark-green {
  background: #0097a2;
}
.eset-btn--dark-green:hover,
.eset-btn--dark-green:active,
.eset-btn--dark-green:focus {
  background: #00676f;
  color: #fff;
}
.eset-btn--white {
  background: #fff;
  color: #00adb6;
  border-radius: 0;
}
.eset-btn--white:hover,
.eset-btn--white:active,
.eset-btn--white:focus {
  background: #8ac649;
  color: #fff;
}
.eset-btn--grey {
  background: #dcdcdc;
  color: #424c56;
}
.eset-btn--grey:hover,
.eset-btn--grey:active,
.eset-btn--grey:focus {
  background: #c3c3c3;
  color: #424c56;
}
.h100-per {
  height: 100%;
}
.bitrix-titling {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 40px;
  color: #fff;
  line-height: 1.5;
  text-align: center;
}
.bitrix-section-titling {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  color: #333333;
}
.bitrix-btn {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 50px;
  background: #b5da2e;
  text-align: center;
  text-transform: uppercase;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  color: #fff;
}
.bitrix-btn:hover,
.bitrix-btn:active,
.bitrix-btn:focus {
  color: #fff;
  background: #95b520;
}
.r {
  border: 1px solid red;
}
.testblock {
  height: 500px;
  width: 100%;
}
.col-xs-5th,
.col-sm-5th,
.col-md-5th,
.col-lg-5th {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-5th {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {
  .col-sm-5th {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-5th {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-5th {
    width: 20%;
    float: left;
  }
}
.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.col-no-padding {
  padding: 0;
}
.row-flex,
.row-flex > div[class*='col-'] {
  display: flex;
  flex: 1 1 auto;
}
.row-flex-wrap {
  -webkit-flex-flow: row wrap;
  align-content: flex-start;
  flex: 0;
}
.row-flex > div[class*='col-'] {
  margin: -0.2px;
  /* hack adjust for wrapping */
}
.hero {
  padding-top: 115px;
  background-image: url(../img/bg/main-bg.jpg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}
.hero-title {
  font-family: "Roboto-Black", sans-serif;
  font-size: 40px;
  line-height: 1.5;
  color: #fff;
}
.hero-btn-holder {
  white-space: nowrap;
  margin-bottom: 100px;
  margin-top: 50px;
}
.hero-btn-white {
  margin-right: 20px;
}
.hero-text {
  font-family: "Roboto Bold", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: #fff;
}
.hero-tiles-row {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-top: 40px;
}
.hero-tile {
  flex: 1 0 360px;
  padding: 50px;
  font-size: 25px;
  line-height: 1.6;
  border-radius: 10px;
  background: #fff;
  text-align: center;
  margin-right: 20px;
}
.hero-tile:last-child {
  margin-right: 0;
}
.hero-tile__accent {
  color: #6b8aea;
}
@media (max-width: 1440px) {
  .hero-tile {
    padding: 30px;
    flex-basis: 200px;
  }
}
@media (max-width: 1200px) {
  .hero-tile {
    padding: 15px;
    flex-basis: 150px;
  }
}
@media (max-width: 991px) {
  .hero-tiles-row {
    flex-direction: column;
  }
  .hero-tile {
    padding: 15px;
    flex-basis: 150px;
    margin-right: 0px;
    flex-basis: auto;
    margin-bottom: 10px;
  }
}
@media (max-width: 606px) {
  .hero-btn-holder {
    white-space: auto;
  }
  .hero-btn-white {
    margin-bottom: 20px;
  }
  .hero-btn-white,
  .hero-btn-blue {
    margin-right: auto;
    margin-left: auto;
    display: block;
    width: 70%;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 2;
  }
  .hero-tile {
    font-size: 18px;
    box-shadow: 0px 2px 7px 0px #A0A0A0;
  }
}
@media (max-width: 520px) {
  .hero-title {
    line-height: 1.3;
    text-align: center;
  }
  .hero-btn-white,
  .hero-btn-blue {
    width: 100%;
    font-size: 14px;
    white-space: pre-wrap;
    line-height: 1.2;
  }
}
.helpers {
  padding-top: 120px;
  background-image: url(../img/bg/notebook-bg.jpg);
  background-repeat: no-repeat;
  background-position: top right;
}
.helpers-title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
}
.helpers-list-item {
  font-family: "Roboto-Regular", sans-serif;
  line-height: 4;
  position: relative;
  padding-left: 30px;
}
.helpers-list-item:before {
  content: '';
  display: block;
  height: 10px;
  width: 10px;
  position: absolute;
  border-radius: 50%;
  background: #8fa7ef;
  top: 50%;
  margin-top: -5px;
  left: 0;
}
@media (max-width: 1450px) {
  .helpers {
    background-position: top right 65%;
  }
}
@media (max-width: 615px) {
  .helpers-list-item {
    line-height: 3;
  }
}
@media (max-width: 550px) {
  .helpers-title {
    line-height: 1.8;
  }
  .helpers-list-item {
    line-height: 2;
  }
}
.interesting {
  padding-top: 120px;
  padding-bottom: 200px;
  background-image: url(../img/bg/bottom-bg.jpg);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
}
.interesting-title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
}
.interesting-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 65px;
}
.interesting-list-item {
  font-family: "Roboto-Bold", sans-serif;
}
.interesting-btn-holder {
  margin-top: 295px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.interesting-btn-blue {
  box-shadow: 0px 9px 18px -3px #dee4f9;
}
.interesting-btn-white {
  box-shadow: 0px 3px 24px 2px #e6e6e6;
}
.interesting-btn-blue,
.interesting-btn-white {
  line-height: 1.5;
  margin: 0 10px;
}
@media (max-width: 1200px) {
  .interesting-list-item {
    font-size: 16px;
  }
}
@media (max-width: 725px) {
  .interesting {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .interesting-list {
    flex-direction: column;
  }
  .interesting-btn-holder {
    margin-top: 50px;
  }
  .interesting-title {
    text-align: center;
  }
  .interesting-list {
    margin-top: 20px;
  }
  .interesting-list-item {
    line-height: 2;
    text-align: center;
  }
}
@media (max-width: 564px) {
  .interesting-btn-holder {
    flex-direction: column;
    -ms-align-items: stretch;
    align-items: stretch;
  }
  .interesting-btn-blue,
  .interesting-btn-white {
    margin-bottom: 10px;
  }
}
@media (max-width: 383px) {
  .interesting-title {
    line-height: 1.5;
  }
  .interesting-btn-holder {
    white-space: normal;
  }
}
.sticker {
  width: 1000px;
  position: relative;
  line-height: 1.5;
}
.sticker--top {
  margin-top: 100px;
  margin-bottom: 50px;
}
.sticker__text {
  font-family: "Roboto Bold", sans-serif;
  color: #fff;
  background: url(../img/bg/sticker.png) no-repeat top center;
  height: 473px;
  width: 641px;
  padding-top: 140px;
  padding-bottom: 180px;
  padding-right: 80px;
  padding-left: 80px;
}
.sticker__subtext {
  color: #262626;
  font-family: "Roboto Bold", sans-serif;
  background: url(../img/bg/sticker-white.png) no-repeat top center;
  width: 532px;
  height: 302px;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-top: 140px;
  padding-bottom: 90px;
  padding-left: 100px;
  padding-right: 75px;
}
.sticker--top .sticker__subtext {
  margin-top: -180px;
  top: 0;
}
@media (max-width: 1200px) {
  .sticker {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .sticker__text {
    width: 60%;
    background-size: contain;
    padding-top: 50px;
  }
  .sticker--top .sticker__text {
    padding-bottom: 100px;
    height: auto;
  }
  .sticker__subtext {
    width: 60%;
    background-size: contain;
    padding-top: 100px;
  }
}
@media (max-width: 735px) {
  .sticker {
    background: #8fa7ef;
    border-radius: 10px;
  }
  .sticker__text {
    background-image: none;
    padding: 20px;
    height: auto;
    color: #fff;
    width: 100%;
  }
  .sticker--top .sticker__text {
    padding: 20px;
  }
  .sticker__subtext {
    padding: 20px;
    background-image: none;
    position: relative;
    height: auto;
    width: 100%;
    display: block;
    color: #fff;
  }
  .sticker--top .sticker__subtext {
    margin: 0;
  }
}
.breadcrumbs {
  font-family: "Roboto-Regular", sans-serif;
}
.breadcrumbs__item {
  display: inline-block;
}
.breadcrumbs__item a {
  color: #fff;
}
.breadcrumbs__item a:hover,
.breadcrumbs__item a:active,
.breadcrumbs__item a:focus {
  text-decoration: underline;
}
.breadcrumbs__item:after {
  content: '/';
  color: #fff;
  margin-left: 5px;
}
.breadcrumbs__item:last-child:after {
  content: '';
}
.enterprisehero-tiles-holder {
  margin-top: 45px;
}
.enterprisehero-tiles-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  -ms-align-items: stretch;
  align-items: stretch;
}
.enterprisehero-tile {
  flex: 1 1 200px;
  width: 150px;
  height: 180px;
  line-height: 1.33;
  border-radius: 10px;
  background: #fff;
  padding: 25px 30px;
  margin-right: 20px;
  box-shadow: 0px 3px 19px 1px #93a3d12e;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.enterprisehero-tile:last-child {
  margin-right: 0;
}
.enterprisehero-tile__text {
  padding-left: 40px;
  display: block;
  position: relative;
}
.enterprisehero-tile__text:before {
  content: '';
  position: absolute;
  display: block;
  height: 10px;
  width: 10px;
  background: #f6e123;
  border-radius: 50%;
  top: 5px;
  left: 0;
}
@media (max-width: 1440px) {
  .enterprisehero-tile {
    font-size: 16px;
    padding: 20px;
  }
  .enterprisehero-tile__text {
    padding-left: 20px;
  }
}
@media (max-width: 776px) {
  .enterprisehero-tiles-row {
    flex-direction: column;
  }
  .enterprisehero-tile {
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;
    height: auto;
    flex-basis: auto;
  }
  .enterprisehero-tile:last-child {
    margin-bottom: 0;
  }
}
.enterprise-info {
  padding-top: 130px;
  padding-bottom: 130px;
  background: url(../img/bg/laptop-bg.jpg) top right no-repeat, url(../img/bg/plant-bg.jpg) top left no-repeat;
}
.enterprise-info-title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
}
.info-text {
  margin-top: 45px;
}
.info-text--bold {
  font-family: "Roboto-Bold", sans-serif;
}
@media (max-width: 1500px) {
  .enterprise-info {
    background: url(../img/bg/laptop-bg.jpg) top right -20% no-repeat;
  }
}
@media (max-width: 1200px) {
  .enterprise-info {
    background: url(../img/bg/laptop-bg.jpg) top right -100% no-repeat;
  }
}
@media (max-width: 992px) {
  .enterprise-info {
    background-image: none;
    padding-top: 70px;
  }
}
@media (max-width: 735px) {
  .enterprise-info {
    padding-bottom: 0;
  }
}
@media (max-width: 390px) {
  .enterprise-info-title {
    line-height: 1.3;
  }
  .info-text {
    margin-top: 20px;
  }
}
.enterprise-special {
  background-image: url(../img/bg/desktop-bg.jpg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
}
.enterprise-special-text {
  margin-bottom: 85px;
  line-height: 1.5;
}
@media (max-width: 735px) {
  .enterprise-special-text {
    margin-bottom: 20px;
  }
}
.stages-title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
}
.stageitem-holder {
  position: relative;
  display: flex;
  flex-direction: column;
}
.stageitem {
  display: block;
  width: 500px;
  padding-left: 170px;
  background-repeat: no-repeat;
  background-position: top left;
  margin-bottom: 50px;
}
.stageitem__num {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 0.8;
  color: #8fa7ef;
}
.stageitem__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 18px;
  line-height: 1.17;
  margin: 20px 0;
}
.stageitem__text {
  color: #6e6e6e;
  font-size: 14px;
  line-height: 1.5;
}
.stage-icon--folder {
  background-image: url(../img/icons/folder.png);
}
.stage-icon--design {
  background-image: url(../img/icons/graphic-design.png);
}
.stage-icon--edit {
  background-image: url(../img/icons/edit.png);
}
.stage-icon--management {
  background-image: url(../img/icons/project-management.png);
}
.stage-icon--rocket {
  background-image: url(../img/icons/rocket.png);
}
.stage-icon--graphic {
  background-image: url(../img/icons/computer-graphic.png);
}
.pos-left {
  left: 0;
  position: relative;
}
.pos-left:before {
  content: '';
  display: block;
  position: absolute;
  width: 220px;
  height: 115px;
  top: 100%;
  left: 100%;
  transform: translateX(-30%);
  background-image: url(../img/icons/arrow-right.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.pos-center--no-icon {
  align-self: center;
}
.pos-center {
  align-self: center;
  position: relative;
}
.pos-center--no-icon:before {
  display: none;
}
.pos-center:before {
  content: '';
  display: block;
  position: absolute;
  width: 239px;
  height: 136px;
  right: 100%;
  bottom: 0;
  background-image: url(../img/icons/arrow-center.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.pos-right {
  margin-left: auto;
  position: relative;
}
.pos-right:before {
  content: '';
  display: block;
  position: absolute;
  width: 220px;
  height: 115px;
  right: 100%;
  bottom: 0;
  transform: translate(40%, 70%);
  background-image: url(../img/icons/arrow-left.png);
  background-repeat: no-repeat;
  background-position: center center;
}
@media (max-width: 1600px) {
  .pos-left:before {
    transform: translateX(-100%);
  }
}
@media (max-width: 1440px) {
  .pos-left:before {
    left: 50%;
    transform: translateX(-50%);
  }
  .pos-right:before {
    transform: translate(80%, 70%);
  }
  .pos-center:before {
    background-image: url(../img/icons/arrow-left-reverse.png);
    background-size: contain;
    width: 120px;
  }
}
@media (max-width: 1200px) {
  .pos-left:before {
    left: 25%;
    transform: translateX(-50%);
  }
  .pos-right:before {
    transform: translate(120%, 90%);
  }
}
@media (max-width: 992px) {
  .pos-left:before {
    left: 0;
    transform: none;
  }
  .pos-right:before {
    transform: translate(140%, 90%);
  }
  .pos-center:before {
    transform: translateX(20%);
  }
}
@media (max-width: 758px) {
  .stageitem-holder {
    align-items: center;
  }
  .pos-left {
    align-self: none;
  }
  .pos-left:before {
    display: none;
  }
  .pos-right {
    margin-left: 0;
    align-self: none;
  }
  .pos-right:before {
    display: none;
  }
  .pos-center {
    align-self: none;
  }
  .pos-center:before {
    display: none;
  }
}
@media (max-width: 540px) {
  .stages-title {
    line-height: 1.3;
  }
  .stageitem {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 140px;
    background-position: top center;
    margin-bottom: 10px;
    flex-shrink: 1;
    width: 100%;
    margin-top: 30px;
  }
  .stageitem__num,
  .stageitem__title {
    text-align: center;
  }
}
.counting-hero {
  padding-top: 110px;
  padding-bottom: 140px;
  background-image: url(../img/bg/bookkeeping-main-bg.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
.counting-hero__title {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 40px;
  line-height: 1.5;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-right: 40px;
  padding-left: 120px;
  background-image: url(../img/bg/bookkeeping-label.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.counting-hero__title .accent-text {
  color: #6b8aea;
}
@media (max-width: 1440px) {
  .counting-hero__title {
    font-size: 35px;
    padding-left: 100px;
  }
}
@media (max-width: 1200px) {
  .counting-hero__title {
    font-size: 30px;
    padding-left: 70px;
  }
}
@media (max-width: 630px) {
  .counting-hero__title {
    line-height: 1.3;
    font-size: 25px;
  }
}
@media (max-width: 485px) {
  .counting-hero {
    padding: 50px 0;
  }
  .counting-hero__title {
    font-size: 18px;
  }
}
@media (max-width: 387px) {
  .counting-hero__title {
    line-height: 1.2;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.stop-support {
  padding-top: 160px;
  padding-bottom: 135px;
  background-image: url(../img/bg/folder-bg.jpg);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 50%;
}
.stop-support__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  line-height: 1.4;
  margin-bottom: 25px;
}
.stop-support__text {
  font-family: "Roboto-Regular", sans-serif;
  margin-bottom: 30px;
  text-indent: 40px;
  text-align: justify;
}
@media (max-width: 572px) {
  .stop-support {
    padding: 70px 0;
  }
}
@media (max-width: 375px) {
  .stop-support__title {
    line-height: 1.2;
    font-size: 20px;
  }
}
.advantages {
  position: relative;
}
.advantages__bg {
  background-image: url(../img/bg/mob1.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  width: 183px;
  height: 600px;
  position: absolute;
  top: -40px;
  left: 0;
}
.advantages__top-line {
  background: #f8e114;
  width: 100%;
}
.advantages__top-title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  color: #000;
}
.advantages__mark-text {
  background: #8fa7ef;
  color: #fff;
  padding: 20px 40px;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 24px;
  line-height: 1.2;
  margin-top: 60px;
  border-radius: 10px;
}
.advantages__list {
  margin-left: 60px;
}
.advantages__list-item {
  line-height: 4;
  list-style-type: none;
  padding-left: 25px;
  position: relative;
}
.advantages__list-item:before {
  content: '';
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  background: #6786e6;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.advantages__simple-text {
  margin-top: 90px;
}
@media (max-width: 1440px) {
  .advantages__top-title {
    line-height: 1.5;
  }
}
@media (max-width: 1200px) {
  .advantages__list {
    margin-top: 20px;
  }
  .advantages__list-item {
    line-height: 2;
  }
  .advantages__list-item:before {
    top: 18px;
  }
}
@media (max-width: 876px) {
  .advantages__bg {
    background-image: none;
  }
}
@media (max-width: 600px) {
  .advantages__top-title {
    font-size: 24px;
  }
  .advantages__mark-text {
    font-size: 20px;
  }
}
@media (max-width: 425px) {
  .advantages__list {
    margin-left: 30px;
  }
  .advantages__list-item {
    line-height: 1.5;
  }
  .advantages__mark-text {
    font-size: 20px;
    margin-top: 40px;
    padding: 20px;
  }
  .advantages__simple-text {
    margin-top: 40px;
  }
}
.jump-to {
  padding: 120px 0;
}
.jump-to__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  margin-bottom: 30px;
  line-height: 1.4;
}
.jump-to__text--margin-top {
  margin-top: 90px;
}
.jump-to__text .accent-text {
  background: #8fa7ef;
}
@media (max-width: 520px) {
  .jump-to__text--margin-top {
    margin-top: 50px;
  }
}
@media (max-width: 425px) {
  .jump-to {
    padding: 60px 0;
  }
}
@media (max-width: 375px) {
  .jump-to__title {
    line-height: 1.2;
    font-size: 20px;
  }
}
.serv-hero {
  padding-top: 100px;
  padding-bottom: 160px;
  background: #6485ea;
  text-align: center;
}
.serv-hero__title {
  font-family: "Roboto-Black", sans-serif;
  font-size: 40px;
  line-height: 1.5;
  color: #fff;
}
.serv-hero__describe {
  font-family: "Roboto-Bold", sans-serif;
  color: #fff;
  padding: 45px 0;
}
.serv-hero__btn-white {
  margin-right: 15px;
}
@media (max-width: 1200px) {
  .serv-hero__btn-white,
  .serv-hero__btn-blue {
    width: 100%;
    display: block;
    padding-top: 0;
    padding-bottom: 0;
  }
  .serv-hero__btn-white {
    margin-bottom: 15px;
  }
}
@media (max-width: 425px) {
  .serv-hero {
    padding: 60px 0;
  }
  .serv-hero__title {
    line-height: 1.2;
    font-size: 35px;
  }
}
@media (max-width: 355px) {
  .serv-hero__btn-blue {
    line-height: 2;
  }
}
.serv-content {
  padding-top: 90px;
}
.serv-content__text {
  margin-bottom: 20px;
}
.serv-content__text-bordered {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 40px 25px;
  border-radius: 10px;
  padding-left: 120px;
  position: relative;
}
.serv-content__text-bordered:before {
  content: '';
  width: 60px;
  height: 2px;
  background: #6485ea;
  display: block;
  position: absolute;
  left: 20px;
  top: 40px;
  margin-top: 10px;
}
.serv-content__text-bordered .accent-text {
  color: #6485ea;
}
@media (max-width: 520px) {
  .serv-content__text-bordered {
    padding-left: 60px;
  }
  .serv-content__text-bordered:before {
    width: 20px;
  }
}
.descrip-hero {
  background: #6485ea;
  padding-top: 100px;
  padding-bottom: 80px;
}
.descrip-hero__title {
  font-family: "Roboto-Black", sans-serif;
  font-size: 40px;
  line-height: 1.5;
  color: #fff;
  margin-bottom: 40px;
}
.descrip-hero__desc {
  color: #fff;
}
.descrip-hero__bottom-line {
  display: flex;
  align-items: center;
  margin-top: 50px;
}
.descrip-hero__btn {
  color: #6b8aea;
  background: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 3px solid transparent;
  display: block;
  padding: 0 50px;
}
.descrip-hero__btn:hover,
.descrip-hero__btn:active,
.descrip-hero__btn:focus {
  border: 3px solid #f8e114;
  background: #f8e114;
  color: #262626;
  text-decoration: none;
}
.descrip-hero__price {
  font-family: "Roboto-Bold", sans-serif;
  color: #fff;
  margin-left: 60px;
}
.description-content-wrapper {
  background: #e8eaee;
  padding-top: 150px;
  padding-bottom: 100px;
  box-shadow: 1.763px 2.427px 15px 0px rgba(0, 0, 0, 0.1);
}
.prod-services {
  margin-bottom: 130px;
}
.prod-services__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  margin-bottom: 50px;
}
.prod-services__tile-wrapper {
  margin: 0 -15px;
}
.prod-services__tile {
  background: #fff;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
  height: 90px;
  padding: 35px 5px;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #262626;
  line-height: 1.2;
  transition: transform 0.3s, color 0.3s, background 0.3s;
}
.prod-services__tile:hover,
.prod-services__tile:active,
.prod-services__tile:focus {
  background: #6485ea;
  color: #fff;
  transform: scale(1.1);
}
@media (max-width: 1450px) {
  .prod-services__tile {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .prod-services__tile {
    height: 50px;
    font-size: 16px;
  }
}
.free-tiles {
  margin-bottom: 120px;
}
.free-tiles__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  margin-bottom: 50px;
}
.free-tiles__holder {
  margin: 0 -15px;
}
.free-tiles__tile-item {
  background: #fff;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s;
  cursor: default;
}
.free-tiles__tile-item-icon {
  margin-top: 20%;
  width: 30%;
  height: 30%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.free-tiles__tile-item-text {
  text-align: center;
  margin-top: 15%;
}
@media (max-width: 1200px) {
  .free-tiles__tile-item-icon--last {
    margin-top: 20px;
    width: 100%;
  }
  .free-tiles__tile-item-text {
    font-size: 16px;
  }
  .free-tiles__tile-item-text--last {
    margin-top: auto;
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .free-tiles {
    margin-bottom: 50px;
  }
  .free-tiles__tile-item-icon--last {
    margin-top: 10%;
    width: 30%;
  }
  .free-tiles__tile-item-text {
    font-size: 18px;
  }
}
@media (max-width: 450px) {
  .free-tiles__tile-item-text {
    font-size: 14px;
  }
}
.label-menu__item {
  text-align: center;
  height: 60px;
  background: #f0f1f4;
  color: #8b8c8d;
  line-height: 60px;
  border-left: 1px solid #e8eaee;
  border-right: 1px solid #e8eaee;
  cursor: pointer;
}
.label-menu__item:first-child {
  border-left: 0;
}
.label-menu__item.is-active {
  background: #fff;
  color: #262626;
}
@media (max-width: 1200px) {
  .label-menu__item {
    height: 80px;
    display: flex;
    line-height: 1;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 992px) {
  .label-menu .md-no-padding {
    padding: 0;
    margin: 0 -10px;
  }
  .label-menu .md-no-margin {
    margin: 0;
  }
  .label-menu__item {
    margin: 0 10px;
    margin-bottom: 20px;
  }
}
@media (max-width: 450px) {
  .label-menu__item {
    font-size: 14px;
  }
}
.no-padding {
  padding: 0;
}
.full-descrip {
  display: none;
}
.full-descrip.is-visible {
  display: block;
}
.full-descrip__wrapper {
  background: #fff;
  padding: 75px 118px;
}
.full-descrip__text {
  margin-bottom: 35px;
}
.full-descrip__text strong {
  font-family: "Roboto-Bold", sans-serif;
}
.full-descrip__heading {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  line-height: 1.3;
  margin-bottom: 35px;
}
.full-descrip__subheading {
  font-family: "Roboto-Bold", sans-serif;
  line-height: 1;
  margin-bottom: 35px;
}
.full-descrip__list-item:before {
  content: '';
  display: inline-block;
  margin-right: 35px;
  width: 10px;
  height: 10px;
  background: #f8e61e;
  border-radius: 50%;
}
.full-descrip ol {
  padding-left: 18px;
}
.full-descrip .mb35 {
  margin-bottom: 35px;
}
.license-desc {
  display: none;
}
.license-desc.is-visible {
  display: block;
}
.license-desc__wrapper {
  background: #fff;
  padding: 0 20px;
}
.license-desc__topline {
  padding: 75px 0;
  padding-left: 100px;
}
.license-desc__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  line-height: 1.3;
}
.license-item {
  padding: 40px 0;
  padding-left: 100px;
  padding-right: 90px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.license-item__desc {
  flex-shrink: 1;
  display: inline-block;
}
.license-item__inner-wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.license-item__price {
  font-family: "Roboto-Bold", sans-serif;
  color: #6485ea;
  font-size: 26px;
  margin-left: auto;
}
.license-item__btn-buy {
  display: block;
  background: #f8e61e;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 14px;
  text-align: center;
  color: #000;
  margin-left: 40px;
}
.license-item__btn-buy:hover,
.license-item__btn-buy:active,
.license-item__btn-buy:focus {
  background: #6485ea;
  color: #fff;
}
@media (max-width: 1200px) {
  .license-item {
    padding-right: 0;
  }
  .license-item__desc {
    padding-right: 20px;
  }
}
@media (max-width: 992px) {
  .license-desc__topline {
    padding-left: 0;
  }
  .license-item {
    flex-direction: column;
    padding-left: 0;
  }
  .license-item__inner-wrapper {
    align-items: flex-start;
    margin: 0;
    margin-top: 20px;
    justify-content: flex-end;
    align-items: center;
  }
  .license-item__price {
    margin-left: 0;
  }
  .license-item__btn-buy {
    line-height: 3.5;
  }
}
@media (max-width: 450px) {
  .license-item__price {
    font-size: 20px;
  }
}
.demo {
  display: none;
}
.demo.is-visible {
  display: block;
}
.demo__wrapper {
  background: #fff;
  padding: 20px;
  padding-bottom: 70px;
}
.demo__topline {
  border-bottom: 1px solid #e8e8e8;
}
.demo__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  line-height: 1.5;
  margin-left: 100px;
  padding-top: 70px;
  padding-bottom: 40px;
}
.demo__content {
  margin-left: 100px;
  padding-top: 55px;
}
.demo__text {
  margin-bottom: 35px;
  font-family: "Roboto-Regular", sans-serif;
}
.demo__subtitle {
  font-family: "Roboto-Bold", sans-serif;
  margin-bottom: 30px;
}
.demo__features {
  padding: 65px 0;
}
.demo__btn {
  display: block;
  background: #f8e61e;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 14px;
  text-align: center;
  color: #000;
  width: 256px;
  margin: 0 auto;
  margin-top: 70px;
}
.demo__btn:hover,
.demo__btn:active,
.demo__btn:focus {
  background: #6485ea;
  color: #fff;
}
.demo-feature {
  display: flex;
  justify-content: flex-start;
  margin-right: 215px;
  margin-left: 150px;
  margin-bottom: 65px;
}
.demo-feature:last-child {
  margin-bottom: 0;
}
.demo-feature__icon {
  flex-shrink: 0;
  width: 85px;
  height: 85px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-right: 40px;
}
.demo-feature__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 12px;
  line-height: 1.5;
}
@media (max-width: 1440px) {
  .demo-feature {
    margin-right: 100px;
    margin-left: 100px;
  }
}
@media (max-width: 1200px) {
  .demo-feature {
    margin-right: 10px;
    margin-left: 10px;
  }
}
@media (max-width: 992px) {
  .demo__title {
    margin-left: 50px;
  }
  .demo__content {
    margin-left: 50px;
  }
}
@media (max-width: 550px) {
  .demo__title {
    margin-left: 0;
  }
  .demo__content {
    margin-left: 0;
  }
  .demo-feature__icon {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
}
@media (max-width: 375px) {
  .demo-feature {
    flex-direction: column;
  }
  .demo-feature__icon {
    width: 70px;
    height: 70px;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.main-hero {
  background: #f8f8f8;
  padding-top: 100px;
  padding-bottom: 75px;
}
.main-hero__tiles-holder {
  display: flex;
  margin: 0 -10px;
}
.mhero-tile {
  background: #fff;
  margin: 0 10px;
  flex: 1 0 0;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  color: #000;
}
.mhero-tile:hover,
.mhero-tile:active,
.mhero-tile:focus {
  color: #000;
}
.mhero-tile--simple {
  overflow: hidden;
}
.mhero-tile--special {
  background: #f8f8f8;
  transform: translateY(200px);
  position: relative;
  border: 1px solid #000;
}
.mhero-tile--special:hover .mhero-tile__text-wrapper,
.mhero-tile--special:active .mhero-tile__text-wrapper,
.mhero-tile--special:focus .mhero-tile__text-wrapper {
  position: relative;
  transform: translateY(-30px);
}
.mhero-tile--special:hover,
.mhero-tile--special:active,
.mhero-tile--special:focus {
  border: 1px solid #A8A8A8;
}
.mhero-tile--special .top-text {
  position: absolute;
  top: -200px;
  width: 100%;
  padding-top: 80px;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 14px;
  line-height: 1.5;
}
.mhero-tile__bg {
  flex: auto 0 250px;
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  transition: transform 0.4s ease-out;
}
.mhero-tile__bg.bgsz70-per {
  background-size: 70%;
}
.mhero-tile__text-wrapper {
  padding: 35px;
  flex: 2 0 auto;
  margin-top: auto;
  overflow: hidden;
  position: relative;
  transition: padding 0.4s ease-out, transform 0.4s ease-out;
}
.mhero-tile--special .mhero-tile__text-wrapper {
  padding-top: 5px;
  padding-bottom: 70px;
}
.mhero-tile__subtitle {
  font-size: 14px;
  line-height: 1.5;
}
.mhero-tile__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 20px;
  line-height: 1.35;
  margin-top: 25px;
}
.mhero-tile__arrow {
  display: block;
  width: 35px;
  height: 4px;
  background: #000;
  position: absolute;
  bottom: -50px;
  left: 35px;
  transition: bottom 0.4s ease-out;
}
.mhero-tile__arrow:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 14px;
  height: 14px;
  border-bottom: 3px solid #000;
  border-right: 3px solid #000;
  transform: rotate(-45deg) translate(30%, -42%);
}
.mhero-tile:hover .mhero-tile__arrow,
.mhero-tile:active .mhero-tile__arrow,
.mhero-tile:focus .mhero-tile__arrow {
  bottom: 35px;
}
.mhero-tile--simple:hover .mhero-tile__text-wrapper,
.mhero-tile--simple:active .mhero-tile__text-wrapper,
.mhero-tile--simple:focus .mhero-tile__text-wrapper {
  padding-top: 15px;
  padding-bottom: 55px;
}
.mhero-tile--simple:hover,
.mhero-tile--simple:active,
.mhero-tile--simple:focus {
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.08);
}
.mhero-tile--simple:hover .mhero-tile__bg,
.mhero-tile--simple:active .mhero-tile__bg,
.mhero-tile--simple:focus .mhero-tile__bg {
  transform: scale(1.05);
}
.attantion-block {
  display: flex;
  align-items: center;
  margin-top: 75px;
}
.attantion-block__icon {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  margin-right: 20px;
  background: #fff;
  font-family: "Roboto-Black", sans-serif;
  font-size: 57px;
  line-height: 100px;
  text-align: center;
  color: #ff0000;
}
.attantion-block__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 20px;
  margin-bottom: 20px;
}
.attantion-block__text {
  font-size: 14px;
}
@media (max-width: 1200px) {
  .main-hero__tiles-holder {
    flex-wrap: wrap;
  }
  .mhero-tile {
    flex-grow: 1;
    flex-basis: 400px;
    width: 400px;
    margin-bottom: 40px;
  }
  .mhero-tile--special {
    transform: none;
  }
  .mhero-tile--special .top-text {
    top: 20px;
  }
  .attantion-block {
    padding: 0 50px;
  }
}
@media (max-width: 992px) {
  .mhero-tile {
    width: 350px;
    flex-basis: 350px;
  }
  .mhero-tile__arrow {
    bottom: 35px;
  }
  .mhero-tile__text-wrapper {
    padding-top: 20px;
    padding-bottom: 55px;
  }
}
@media (max-width: 768px) {
  .main-hero__tiles-holder {
    padding-left: 10%;
    padding-right: 10%;
    margin-left: 0;
    margin-right: 0;
  }
  .mhero-tile {
    width: 80%;
    flex-basis: 80%;
  }
}
@media (max-width: 668px) {
  .main-hero__tiles-holder {
    display: block;
  }
  .mhero-tile {
    width: 100%;
    height: 430px;
  }
}
@media (max-width: 425px) {
  .main-hero__tiles-holder {
    padding-left: 10px;
    padding-right: 10px;
  }
  .mhero-tile {
    margin-left: 5px;
    margin-right: 5px;
    height: 460px;
  }
  .mhero-tile__title {
    font-size: 18px;
    line-height: 1.2;
  }
  .attantion-block {
    text-align: center;
    padding: 0 20px;
    flex-direction: column;
  }
  .attantion-block__icon {
    margin-bottom: 20px;
    margin-right: 0;
  }
}
.slick-dots {
  margin: 0 auto;
}
.slick-dots li {
  display: inline-block;
  margin-right: 30px;
}
.slick-dots li:last-child {
  margin-right: 0;
}
.slick-dots li button {
  display: block;
  width: 15px;
  height: 15px;
  background: #ccc;
  border-radius: 50%;
  border: 0;
  padding: 0;
  font-size: 0;
}
.slick-dots .slick-active button {
  background: #ff5858;
}
.main-programs {
  padding-top: 75px;
  border-bottom: 1px solid #f8f8f8;
}
.main-programs__row {
  margin-bottom: 70px;
}
.main-programs__row-title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  margin-bottom: 50px;
}
.programs-item {
  display: flex;
  align-items: center;
  color: #333333;
}
.programs-item__icon {
  width: 100px;
  height: 100px;
  margin-right: 8%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.programs-item__content {
  width: 100%;
}
.programs-item__name {
  margin-bottom: 20px;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 16px;
  line-height: 1.31;
}
.programs-item__line {
  display: block;
  width: 0%;
  height: 2px;
  background: #4b6eb3;
  position: relative;
  transition: width 0.5s ease-out;
}
.programs-item__line:after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background: #4b6eb3;
  position: absolute;
  right: -4px;
  top: -3px;
  border-radius: 50%;
}
.programs-item:hover .programs-item__line,
.programs-item:active .programs-item__line,
.programs-item:focus .programs-item__line {
  width: 100%;
}
.programs-item:hover,
.programs-item:active,
.programs-item:focus {
  color: #4b6eb3;
}
.main-abilities {
  padding: 95px 0;
}
.main-abilities__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  margin-bottom: 65px;
}
.ability {
  display: flex;
  margin-bottom: 70px;
}
.ability__icon {
  flex: 1 0 auto;
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-right: 45px;
}
.ability__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 20px;
  margin-bottom: 25px;
}
.ability__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 25px;
}
.ability__text:last-child {
  margin-bottom: 0;
}
.enterprise-block__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  margin-bottom: 55px;
}
.enterprise-block__links-holder {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.enterprise-block__link {
  display: block;
  position: relative;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 20px;
  letter-spacing: 2.5px;
  padding-bottom: 40px;
  border-bottom: 2px solid #000;
  color: #000;
  overflow: hidden;
  transition: padding-right 0.4s ease-out;
}
.enterprise-block__link:hover,
.enterprise-block__link:active,
.enterprise-block__link:focus {
  color: #4267af;
  border-color: #4267af;
  padding-right: 60px;
}
.enterprise-block__link:hover .abs-arrow,
.enterprise-block__link:active .abs-arrow,
.enterprise-block__link:focus .abs-arrow {
  background-color: #4267af;
}
.enterprise-block__link:hover .abs-arrow:after,
.enterprise-block__link:active .abs-arrow:after,
.enterprise-block__link:focus .abs-arrow:after {
  border-color: #4267af;
}
.enterprise-block__link .abs-arrow {
  display: block;
  width: 35px;
  height: 4px;
  background: #000;
  position: absolute;
  margin-left: 10px;
  top: 0;
  margin-top: 14px;
  transition: bottom 0.4s ease-out;
}
.enterprise-block__link .abs-arrow--fix113 {
  left: 113px;
}
.enterprise-block__link .abs-arrow--fix138 {
  left: 138px;
}
.enterprise-block__link .abs-arrow:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 14px;
  height: 14px;
  border-bottom: 3px solid #000;
  border-right: 3px solid #000;
  transform: rotate(-45deg) translate(30%, -42%);
}
@media (max-width: 460px) {
  .enterprise-block__links-holder {
    display: block;
  }
  .enterprise-block__link {
    display: block;
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 0;
  }
}
@media (max-width: 580px) {
  .ability {
    display: block;
  }
  .ability__icon {
    float: left;
    width: 100px;
    height: 100px;
    margin-right: 40px;
    margin-bottom: 20px;
  }
}
.main-about {
  padding-top: 100px;
  padding-bottom: 95px;
  background: #f8f8f8;
}
.main-about__inner-wrapper {
  position: relative;
  margin-bottom: 75px;
}
.main-about__bg {
  background-image: url(../img/bg/main-about-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 45%;
  position: absolute;
  left: 0;
}
.main-about__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  line-height: 1;
  padding-bottom: 25px;
  padding-right: 75px;
  border-bottom: 2px solid #6485ea;
  margin-bottom: 50px;
  box-sizing: border-box;
  display: inline-block;
}
.main-about__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #535353;
}
.main-about__text .link-more {
  font-family: "Roboto-Bold", sans-serif;
  text-decoration: underline;
}
.main-about__statistics {
  margin-top: 75px;
}
.main-about__statistics-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
}
.main-about__statistics-row:last-child {
  margin-bottom: 0;
}
.main-about__btn-holder {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}
.main-about__btn {
  width: 225px;
  display: inline-block;
  border-radius: 0;
  background: #fff;
}
.main-about__work-label {
  padding-top: 50px;
  padding-bottom: 95px;
  margin-top: 30px;
  background-image: url(../img/bg/ukraine-map.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}
.main-about__work-label-title {
  font-family: "Roboto-Bold", sans-serif;
  line-height: 1;
  font-size: 20px;
}
.main-about__advantages {
  margin-top: 75px;
}
.main-about__advantages-title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  padding-bottom: 20px;
  border-bottom: 1px solid #6485ea;
}
.main-about__advantages-holder {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  margin-left: -50px;
  margin-right: -50px;
}
.main-about__advantages-row {
  display: flex;
}
.statistics-item {
  display: block;
  width: 120px;
  padding-bottom: 20px;
  position: relative;
}
.statistics-item:before {
  content: '';
  position: absolute;
  display: block;
  height: 1px;
  width: 28px;
  background: #e8e8e8;
  bottom: 0;
  left: 0;
}
.statistics-item__value {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 40px;
  color: #446ab2;
  margin-bottom: 18px;
}
.statistics-item__descrip {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  color: #333333;
  line-height: 1.5;
}
.main-advantage {
  margin: 0 50px;
  width: 150px;
  flex: 1 0 130px;
}
.main-advantage__icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center center;
}
.main-advantage__descrip {
  margin-top: 30px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  line-height: 1.5;
}
@media (max-width: 1200px) {
  .main-about__advantages-holder {
    margin-left: -25px;
    margin-right: -25px;
    flex-wrap: wrap;
  }
  .main-about__btn-holder {
    flex-direction: column;
  }
  .main-about__btn {
    margin-bottom: 20px;
  }
}
@media (max-width: 1270px) {
  .main-advantage {
    margin: 0 25px;
  }
  .main-advantage__icon {
    margin: 0 auto;
  }
  .main-advantage__descrip {
    text-align: center;
  }
}
@media (max-width: 992px) {
  .main-about {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .main-about__bg {
    background-image: none;
  }
  .main-about__statistics-row {
    justify-content: space-around;
  }
  .main-about__advantages-holder {
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
  }
  .main-about__advantages-row {
    width: 100%;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  .main-about__work-label {
    text-align: center;
    background-position: center center;
  }
  .main-about__btn-holder {
    flex-direction: row;
    justify-content: center;
  }
  .main-about__btn {
    margin-bottom: 0;
    margin-right: 20px;
  }
  .main-about__btn:last-child {
    margin-right: 0;
  }
  .main-advantage {
    margin: 0;
  }
  .main-advantage__icon {
    width: 80px;
    height: 80px;
  }
}
@media (max-width: 460px) {
  .main-about {
    padding-bottom: 40px;
  }
  .main-about__btn-holder {
    flex-direction: column;
  }
  .main-about__advantages-row {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
  .main-about__btn {
    margin: 0;
    width: 100%;
    margin-bottom: 20px;
  }
  .main-advantage {
    margin-bottom: 20px;
    width: 250px;
  }
}
.main-news {
  padding: 100px 0;
}
.main-news__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  padding-bottom: 25px;
  border-bottom: 1px solid #6485ea;
}
.main-news__row {
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 -25px;
  border-bottom: 1px solid #eeeeee;
}
.news-item {
  margin: 0 25px;
  flex: 1;
  overflow: hidden;
}
.news-item--flex2 {
  flex: 2;
}
.news-item__lg-img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: transform 0.3s ease-in-out;
}
.news-item__date {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  color: #a4a4a4;
  margin-bottom: 40px;
}
.news-item__title {
  color: #000;
  font-family: "Roboto-Bold", sans-serif;
  line-height: 1.5;
  padding-bottom: 25px;
  margin-bottom: 20px;
  position: relative;
}
.news-item__title .anim-line {
  height: 2px;
  width: 0%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #4b6eb3;
  transition: width 0.4s ease-in-out;
}
.news-item__title .anim-line:before {
  content: '';
  position: absolute;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #4b6eb3;
  top: -2px;
  right: -6px;
}
.news-item__text {
  font-size: 14px;
  color: #333333;
  line-height: 1.5;
}
.news-item__img {
  height: 85px;
  width: 100%;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  transition: transform 0.3s ease-in-out;
}
.news-item:hover .news-item__img,
.news-item:active .news-item__img,
.news-item:focus .news-item__img {
  transform: scale(1.05);
}
.news-item:hover .news-item__title,
.news-item:active .news-item__title,
.news-item:focus .news-item__title {
  color: #6485ea;
}
.news-item:hover .anim-line,
.news-item:active .anim-line,
.news-item:focus .anim-line {
  width: 100%;
}
.news-item:hover .anim-line:before,
.news-item:active .anim-line:before,
.news-item:focus .anim-line:before {
  right: 0;
}
@media (max-width: 992px) {
  .main-news {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .main-news__wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .main-news__row {
    margin: 0 20px;
    flex-direction: column;
  }
  .news-item {
    order: 2;
    margin: 0;
    margin-bottom: 30px;
  }
  .news-item--flex2 {
    order: 1;
  }
  .news-item__lg-img {
    width: 100%;
    padding-top: 53%;
  }
  .news-item__img {
    width: 180px;
  }
}
@media (max-width: 460px) {
  .main-news__wrapper {
    flex-wrap: wrap;
  }
  .main-news__row {
    padding: 20px 0;
  }
  .news-item__date {
    margin-bottom: 20px;
  }
}
.tolider__wrapper {
  display: flex;
}
.tolider__quote-holder {
  flex: 1;
}
.tolider__form-holder {
  flex: 1;
}
.quote-content {
  width: 62%;
  padding-top: 90px;
  padding-bottom: 65px;
  position: relative;
}
.quote-content--about {
  padding-top: 0;
}
.quote-content--about:before {
  display: none !important;
}
.quote-content__img {
  width: 100%;
  height: auto;
  margin-bottom: 45px;
}
.quote-content__quote {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: #000;
  margin-bottom: 30px;
}
.quote-content__author {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
}
.quote-content:before,
.quote-content:after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.quote-content:before {
  background-image: url(../img/icons/quote-up.png);
  left: 0;
  top: 0;
}
.quote-content:after {
  background-image: url(../img/icons/quote-down.png);
  right: 0;
  bottom: 0;
}
.tolider-form__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
}
.tolider-form__input {
  display: block;
  width: 100%;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  padding: 20px 0;
  border: 0;
  border-bottom: 1px solid #000;
  margin-bottom: 20px;
  outline: 0;
}
.tolider-form__submit {
  border: 0;
  width: 100%;
  margin-top: 70px;
}
@media (max-width: 768px) {
  .tolider__wrapper {
    display: flex;
    flex-direction: column;
  }
  .quote-content {
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .quote-content:before,
  .quote-content:after {
    width: 30px;
    height: 24px;
  }
}
.main-seo__content::-webkit-scrollbar {
  width: 6px;
}
.main-seo__content::-webkit-scrollbar-track {
  background: #eee;
}
.main-seo__content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ccc;
}
.main-seo {
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: 1px solid #f8f8f8;
}
.main-seo__content {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
}
.main-seo__text-block {
  margin-top: 20px;
}
.main-seo__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  margin-bottom: 20px;
}
.main-seo__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  color: #535353;
}
.main-seo__list {
  margin-left: 20px;
}
.main-seo__list-item {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  color: #535353;
  position: relative;
  padding-left: 25px;
  line-height: 1.5;
  margin-bottom: 10px;
}
.main-seo__list-item:before {
  content: '';
  display: block;
  position: absolute;
  top: 7px;
  left: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #4267af;
}
.about-hero {
  background: #6485ea;
  padding-top: 50px;
  padding-bottom: 100px;
  background-image: url(../img/bg/about-hero-bg.png);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: bottom right;
}
.about-hero__title {
  font-family: "Roboto-Black", sans-serif;
  font-size: 40px;
  line-height: 1.5;
  color: #fff;
}
.about-hero__text {
  color: #fff;
  margin-top: 50px;
  font-family: "Roboto-Regular", sans-serif;
}
@media (max-width: 992px) {
  .about-hero {
    background-size: 70%;
  }
}
@media (max-width: 768px) {
  .about-hero {
    background-image: none;
  }
}
.about-intro {
  position: relative;
}
.about-intro__top-line {
  padding-top: 95px;
  padding-bottom: 95px;
}
.about-intro__bottom-line {
  padding-top: 95px;
  padding-bottom: 95px;
  background: #e8eaee;
}
.about-intro__abs-bg {
  background-image: url(../img/bg/about-side-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 43%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 768px) {
  .about-intro__abs-bg {
    display: none;
  }
}
.about-blueline {
  font-family: "Roboto-Regular", sans-serif;
  padding-top: 75px;
  padding-bottom: 75px;
  background: #6485ea;
}
.about-blueline__text {
  color: #fff;
  margin-bottom: 35px;
}
.about-blueline__text .underlined {
  font-family: "Roboto-Bold", sans-serif;
  display: inline-block;
  border-bottom: 2px solid #f8e304;
}
.about-blueline__text:last-child {
  margin-bottom: 0;
}
.about-work {
  padding-top: 95px;
  padding-bottom: 95px;
  background: #e8eaee;
}
.about-work__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
}
.about-work__stat {
  margin-top: 50px;
}
.about-work__stat-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}
.about-work__stat-item {
  font-family: "Roboto-Regular", sans-serif;
  flex: 1 0 95px;
}
.about-work__stat-item-value {
  color: #446ab2;
  font-size: 40px;
}
.about-work__stat-item-descrip {
  color: #333;
  font-size: 14px;
  line-height: 1.5;
}
.about-work__stat-btn-holder {
  display: flex;
  margin: 0 -10px;
}
.about-work__stat-btn {
  color: #6b8aea;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 3px solid transparent;
  line-height: 1;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 10px;
  flex: 1;
  color: #446ab3;
  background: #fff;
  border-radius: 0;
}
.about-work__stat-btn:hover,
.about-work__stat-btn:active,
.about-work__stat-btn:focus {
  border: 3px solid #f8e114;
  background: #f8e114;
  color: #262626;
  text-decoration: none;
}
.about-work__bg {
  width: 100%;
  background-image: url(../img/bg/ukraine-map-2.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  height: 400px;
}
@media (max-width: 1450px) {
  .about-work__stat-btn-holder {
    flex-direction: column;
  }
  .about-work__stat-btn {
    margin-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .about-work__stat-btn-holder {
    flex-direction: row;
  }
  .about-work__stat-btn {
    margin-bottom: 0;
  }
}
@media (max-width: 420px) {
  .about-work__stat-row {
    flex-direction: column;
    margin-bottom: 0;
  }
  .about-work__stat-btn-holder {
    margin-top: 40px;
    flex-direction: column;
  }
  .about-work__stat-btn {
    margin-bottom: 10px;
  }
}
.about-partners {
  padding-top: 75px;
  padding-bottom: 95px;
}
.about-partners__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  line-height: 2.3;
  text-align: center;
  margin-bottom: 50px;
}
.about-partners__content-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -50px;
  margin-bottom: 90px;
}
.about-partners__content-row:last-child {
  margin-bottom: 0;
}
.about-partners .partner-link {
  flex: 1 1 auto;
  margin: 0 50px;
  display: block;
}
.about-partners .about-partner {
  width: 100%;
  height: auto;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.3;
}
.about-partners .about-partner:hover {
  filter: none;
  opacity: 1;
}
@media (max-width: 1270px) {
  .about-partners__content-row {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
  }
  .about-partners__content-row:last-child {
    margin-bottom: 0;
  }
  .about-partners .partner-link {
    flex-grow: 0;
    margin-bottom: 40px;
  }
}
@media (max-width: 860px) {
  .about-partners__content-row {
    margin-left: 0;
    margin-right: 0;
  }
  .about-partners .partner-link {
    flex-grow: 0;
    flex-shrink: 1;
  }
}
.eset-hero {
  padding-top: 50px;
  padding-bottom: 100px;
  background-image: url(../img/bg/eset-hero-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.eset-hero--smb {
  background-image: url(../img/bg/eset-smb-hero-bg.jpg);
}
.eset-hero--mdb {
  background-image: url(../img/bg/eset-mdb-hero-bg.jpg);
}
.eset-hero--corpor {
  background-image: url(../img/bg/eset-corpor-hero-bg.jpg);
}
.eset-hero__content {
  margin-top: 60px;
}
.eset-hero__pre-title {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  text-align: center;
  color: #fff;
  margin-bottom: 35px;
}
.eset-hero__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 50px;
  line-height: 1.2;
  color: #fff;
  text-align: center;
  margin-bottom: 60px;
}
.eset-hero__description {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 26px;
  line-height: 1;
  color: #fff;
  text-align: center;
}
.eset-hero__description strong {
  font-family: "Roboto-Bold", sans-serif;
}
.eset-hero__btn {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  color: #fff;
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  width: 315px;
  margin: 0 auto;
  margin-top: 80px;
}
.eset-hero__btn--light-green {
  background: #8ac649;
}
.eset-hero__btn--light-green:hover,
.eset-hero__btn--light-green:active,
.eset-hero__btn--light-green:focus {
  background: #70a834;
  color: #fff;
}
.eset-hero__btn--dark-green {
  background: #0097a2;
}
.eset-hero__btn--dark-green:hover,
.eset-hero__btn--dark-green:active,
.eset-hero__btn--dark-green:focus {
  background: #00676f;
  color: #fff;
}
.eset-hero__btn--white {
  background: #fff;
  color: #00adb6;
  border-radius: 0;
}
.eset-hero__btn--white:hover,
.eset-hero__btn--white:active,
.eset-hero__btn--white:focus {
  background: #8ac649;
  color: #fff;
}
.eset-hero__btn--grey {
  background: #dcdcdc;
  color: #424c56;
}
.eset-hero__btn--grey:hover,
.eset-hero__btn--grey:active,
.eset-hero__btn--grey:focus {
  background: #c3c3c3;
  color: #424c56;
}
.eset-hero__link-holder {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  color: #fff;
}
.eset-hero__intro {
  font-family: "Roboto-Regular", sans-serif;
  display: inline-block;
  margin-right: 15px;
}
.eset-hero__link {
  font-family: "Roboto-Bold", sans-serif;
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  color: #fff;
  text-decoration: underline;
}
.eset-hero__link:hover,
.eset-hero__link:active,
.eset-hero__link:focus {
  color: #8ac649;
  text-decoration: underline;
}
.eset-greyline {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f4f5f4;
}
.eset-greyline__text {
  text-align: center;
}
@media (max-width: 750px) {
  .eset-hero__title {
    font-size: 40px;
  }
  .eset-hero__description {
    font-size: 22px;
    line-height: 1.3;
  }
  .eset-hero__link-holder {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 375px) {
  .eset-hero__btn {
    width: 100%;
  }
}
.eset-innovations {
  padding-top: 100px;
  padding-bottom: 100px;
  background: #fbfbfb;
}
.eset-innovations__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 35px;
  line-height: 1.43;
  color: #424c56;
  text-align: center;
  margin-bottom: 40px;
}
.eset-innovations__description {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  color: #424c56;
  text-align: center;
}
.eset-innovations__items-holder {
  margin-top: 80px;
  display: flex;
}
.eset-innovations__items-row {
  display: flex;
  justify-content: center;
  flex: 0 1 auto;
  margin-left: -30px;
  margin-right: -30px;
}
.eset-innovations__items-row:first-child {
  margin-right: 60px;
}
.innovation-item {
  flex: 1 0 220px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 50px;
}
.innovation-item__icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.innovation-item__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 20px;
  color: #424c56;
  line-height: 1.2;
  margin-top: 25px;
  margin-bottom: 25px;
}
.innovation-item__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 17px;
  color: #424c56;
  line-height: 1.47;
}
@media (max-width: 1440px) {
  .eset-innovations__items-holder {
    flex-direction: column;
  }
  .eset-innovations__items-row {
    justify-content: flex-start;
    align-items: stretch;
  }
  .innovation-item {
    flex-grow: 0;
    flex-basis: 300px;
  }
}
@media (max-width: 1200px) {
  .eset-innovations__items-holder {
    flex-direction: column;
  }
  .eset-innovations__items-row {
    flex-direction: column;
  }
  .innovation-item {
    margin-bottom: 30px;
  }
}
@media (max-width: 992px) {
  .eset-innovations__items-row {
    margin-left: 0;
    margin-right: 0;
  }
  .innovation-item {
    margin-right: 0;
    margin-left: 0;
  }
}
.eset-features__inner-row {
  border-bottom: 3px solid #fbfbfb;
}
.eset-features__inner-row:last-child {
  border-bottom: 0;
}
.eset-feature {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  align-items: center;
}
.eset-feature__icon {
  width: 100px;
  height: 100px;
  margin-right: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.eset-feature__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  color: #424c56;
  width: 300px;
}
.eset-feature__more-link {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  color: #56d0d4;
  cursor: pointer;
  margin-left: auto;
  position: relative;
}
.eset-feature__more-link:after {
  content: '+';
  position: absolute;
  left: 100%;
  margin-left: 30px;
}
.eset-feature__more-link.open:after {
  content: '-';
}
.eset-feature__more-link:hover,
.eset-feature__more-link:active,
.eset-feature__more-link:focus {
  color: #32c0c5;
}
.f-droplist {
  display: none;
}
.f-droplist.open {
  display: block;
}
.f-droplist-item {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  background: #fbfbfb;
  border-bottom: 1px solid #969696;
}
.f-droplist-item__title {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 26px;
  color: #424c56;
  cursor: pointer;
  display: inline-block;
}
.f-droplist-item__title:before {
  content: '+';
  position: absolute;
  right: 100%;
  margin-right: 55px;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  color: #56d0d4;
}
.f-droplist-item__title.open:before {
  content: '-';
}
.f-subdrop {
  margin-top: 50px;
  display: none;
}
.f-subdrop__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  color: #56d0d4;
}
.f-subdrop__descrip {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 17px;
  line-height: 1.24;
  width: 200px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.f-subdrop__btn {
  width: 200px;
  font-size: 14px;
  border-radius: 0;
}
@media (max-width: 992px) {
  .eset-feature__more-link {
    margin-right: 50px;
  }
  .f-droplist-item__title {
    margin-left: 30px;
    padding-left: 20px;
  }
  .f-droplist-item__title:before {
    margin-right: 0;
    right: auto;
    left: 20px;
  }
}
@media (max-width: 670px) {
  .eset-feature__icon {
    margin-right: 20px;
  }
  .eset-feature__title {
    font-size: 22px;
  }
  .eset-feature__more-link {
    font-size: 22px;
  }
}
@media (max-width: 560px) {
  .eset-feature {
    flex-wrap: wrap;
    justify-content: center;
  }
  .eset-feature__icon {
    flex-shrink: 0;
    margin-right: 0;
  }
  .eset-feature__title {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .eset-feature__more-link {
    margin-left: auto;
    margin-right: auto;
  }
  .eset-feature__more-link:after {
    margin-left: 5px;
  }
  .f-droplist-item__title {
    font-size: 22px;
  }
}
.eset-solutions {
  background: #fbfbfb;
  padding-top: 120px;
  padding-bottom: 150px;
}
.eset-solutions__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 35px;
  color: #424c56;
  text-align: center;
  margin-bottom: 75px;
}
.eset-solutions-item {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 90px;
  padding-bottom: 65px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.eset-solutions-item__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 30px;
  color: #fff;
  margin-bottom: 25px;
}
.eset-solutions-item__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: #fff;
  margin-bottom: 30px;
}
.eset-solutions-item__btn {
  margin-top: auto;
  width: 216px;
}
@media (max-width: 1200px) {
  .eset-solutions .row-flex,
  .eset-solutions .row-flex > div[class*='col-'] {
    display: block;
  }
  .eset-solutions-item {
    width: 100%;
    display: block;
  }
}
@media (max-width: 768px) {
  .eset-solutions {
    padding-top: 70px;
    padding-bottom: 50px;
  }
  .eset-solutions-item {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .eset-solutions-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 450px) {
  .eset-solutions-item__btn {
    width: 100%;
  }
}
.eset-try {
  padding: 60px 0;
  background-image: url(../img/bg/eset-try-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  text-align: center;
  color: #fff;
}
.eset-try__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 35px;
  margin-bottom: 35px;
  color: #fff;
}
.eset-try__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 35px;
}
.eset-try__btn {
  max-width: 313px;
  margin: 0 auto;
}
.eset-mng {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #fbfbfb;
}
.eset-mng__wrapper {
  display: flex;
  align-items: stretch;
}
.eset-mng__content {
  flex: 1 0 50%;
}
.eset-mng__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 35px;
  line-height: 1.43;
  color: #424c56;
}
.eset-mng__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  margin-top: 45px;
}
.eset-mng__text .inner-link {
  color: #01a1b3;
}
.eset-mng__text .inner-link:hover,
.eset-mng__text .inner-link:active,
.eset-mng__text .inner-link:focus {
  color: #017380;
}
.eset-mng__corp-wrapper {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: #424c56;
  margin-top: 90px;
}
.eset-mng__corp-list {
  margin-top: 35px;
}
.eset-mng__corp-list-item {
  padding-left: 30px;
  position: relative;
  margin-bottom: 35px;
}
.eset-mng__corp-list-item:last-child {
  margin-bottom: 0;
}
.eset-mng__corp-list-item:before {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #4467b0;
}
.eset-mng__corp-more-link {
  margin-left: 30px;
  color: #00abb4;
  text-decoration: underline;
  margin-top: 40px;
  display: inline-block;
}
.eset-mng__corp-more-link:hover,
.eset-mng__corp-more-link:active,
.eset-mng__corp-more-link:focus {
  color: #007b81;
  text-decoration: underline;
}
.eset-mng__corp-btn {
  margin-top: 70px;
  width: 370px;
}
.eset-mng__bg {
  flex: 1 0 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.eset-mng__bg--mdb {
  background-size: contain;
  background-position: center right;
}
.eset-mng__bg--corp {
  background-size: 80%;
  background-position: top right;
}
@media (max-width: 1200px) {
  .eset-mng__content {
    flex: 3;
  }
  .eset-mng__bg {
    flex: 2;
    margin-left: 50px;
  }
}
@media (max-width: 768px) {
  .eset-mng__wrapper {
    flex-direction: column;
  }
  .eset-mng__content {
    order: 2;
  }
  .eset-mng__bg {
    margin-left: 0;
    height: 300px;
    order: 1;
  }
  .eset-mng__bg--mdb {
    background-position: center center;
    background-size: 50%;
  }
  .eset-mng__bg--corp {
    background-position: center left;
    background-size: contain;
    margin-bottom: 30px;
  }
}
@media (max-width: 420px) {
  .eset-mng__corp-btn {
    width: 100%;
  }
}
.eset-checks {
  padding: 30px 0;
}
.eset-checks__item {
  position: relative;
  flex: 1 0 150px;
  margin: 0 45px;
  padding-left: 40px;
}
.eset-checks__item:before {
  content: '';
  width: 25px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../img/eset-icons/check-green.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
@media (max-width: 1200px) {
  .eset-checks__item {
    margin-bottom: 20px;
  }
}
.eset-smb-avards {
  padding: 120px 0;
  background: #fbfbfb;
}
.eset-smb-avards__wrap {
  display: flex;
}
.eset-smb-avards__avards-holder {
  flex: 4 1 70%;
  display: flex;
  justify-content: space-between;
}
.eset-smb-avards__content {
  flex: 2 1 30%;
  margin-left: 70px;
}
.eset-smb-avards__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 35px;
  line-height: 1.43;
  color: #424c56;
}
.eset-smb-avards__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  color: #424c56;
  margin-top: 45px;
}
.eset-smb-avard {
  margin: 0 35px;
}
.eset-smb-avard__icon {
  width: 90px;
  height: 90px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.eset-smb-avard__descrip {
  margin-top: 45px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: #acafb3;
}
@media (max-width: 1200px) {
  .eset-smb-avards__wrap {
    flex-direction: column;
  }
  .eset-smb-avards__avards-holder {
    order: 2;
    margin-top: 50px;
  }
  .eset-smb-avards__content {
    order: 1;
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .eset-smb-avards__avards-holder {
    flex-direction: column;
  }
  .eset-smb-avard {
    display: flex;
    margin-bottom: 30px;
  }
  .eset-smb-avard__icon {
    flex-shrink: 0;
    float: left;
    margin-right: 40px;
  }
  .eset-smb-avard__descrip {
    margin-top: 0;
  }
}
@media (max-width: 480px) {
  .eset-smb-avard {
    margin-right: 0;
    margin-left: 0;
  }
  .eset-smb-avard__icon {
    margin-right: 20px;
  }
}
.eset-defence {
  padding: 100px 0;
  background-image: url(../img/bg/eset-defence-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
}
.eset-defence--corp {
  background-image: url(../img/bg/eset-corp-defence.jpg);
}
.eset-defence__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 35px;
  line-height: 1.43;
  color: #424c56;
  color: #fff;
  text-align: center;
}
.eset-defence__descrip {
  text-align: center;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: #fff;
  margin-top: 50px;
}
.eset-defence__item-wrapper {
  margin-top: 80px;
  display: flex;
  margin-left: -50px;
  margin-right: -50px;
}
.eset-defence__details-btn {
  width: 250px;
  border-radius: 3px;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 120px;
}
.defence-item {
  margin: 0 50px;
  flex: 1 0 0;
}
.defence-item__icon {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.defence-item__icon--mdb {
  background-size: 70%;
}
.defence-item__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 20px;
  line-height: 1.2;
  color: #fff;
  margin-top: 45px;
}
.defence-item__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 17px;
  line-height: 1.47;
  color: #fff;
  margin-top: 25px;
}
@media (max-width: 992px) {
  .eset-defence__item-wrapper {
    margin-left: -25px;
    margin-right: -25px;
  }
  .defence-item {
    margin-left: 25px;
    margin-right: 25px;
  }
}
@media (max-width: 768px) {
  .eset-defence__item-wrapper {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
  .defence-item {
    margin-bottom: 50px;
  }
  .defence-item__icon {
    width: 70px;
    height: 70px;
  }
  .defence-item__title {
    margin-top: 20px;
  }
}
.complex-solutions {
  padding-top: 130px;
  margin-bottom: 45px;
}
.complex-solutions__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 35px;
  line-height: 1.43;
  color: #424c56;
  text-align: center;
}
.complex-solutions__btn {
  border-radius: 0;
  width: 100%;
  margin-top: 70px;
  text-transform: uppercase;
  padding-top: 15px;
  padding-bottom: 15px;
}
.complex-solutions__item-holder {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-left: -50px;
  margin-right: -50px;
  margin-top: 50px;
  margin-bottom: 150px;
}
.complex-solutions__bottom-text {
  font-family: "Roboto-Italic", sans-serif;
  font-size: 16px;
  color: #424c56;
  text-align: center;
}
.complex-solution-item {
  flex: 1 0 0;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
}
.complex-solution-item__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 30px;
  color: #424c56;
  margin-bottom: 55px;
}
.complex-solution-item__list {
  margin-top: auto;
  margin-bottom: 40px;
}
.complex-solution-item__list-item {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  color: #424c56;
  padding-left: 40px;
  position: relative;
}
.complex-solution-item__list-item:before {
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 3px;
  background-image: url(../img/eset-icons/check-green.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.complex-solution-item__list-item.canceled {
  color: #dcdcdc;
}
.complex-solution-item__list-item.canceled:before {
  background-image: url(../img/eset-icons/cross-icon.png);
}
.complex-solution-item__try-btn {
  margin-top: 10px;
}
@media (max-width: 1250px) {
  .complex-solutions__item-holder {
    margin-left: -20px;
    margin-right: -20px;
  }
  .complex-solution-item {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (max-width: 1055px) {
  .complex-solutions__item-holder {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .complex-solution-item {
    margin-bottom: 50px;
    margin-left: 0;
    margin-right: 0;
    display: block;
  }
  .complex-solution-item__title {
    margin-bottom: 25px;
  }
  .complex-solution-item__details-btn {
    width: 50%;
  }
  .complex-solution-item__try-btn {
    width: 50%;
  }
}
@media (max-width: 550px) {
  .complex-solution-item__details-btn,
  .complex-solution-item__try-btn {
    width: 100%;
  }
}
.eset-console {
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: 1px solid #969696;
  border-bottom: 1px solid #969696;
  margin-bottom: 40px;
}
.eset-console__title {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 26px;
  color: #424c56;
  position: relative;
}
.eset-console__title:before {
  content: '-';
  position: absolute;
  color: #56d0d4;
  left: -55px;
}
.eset-console__holder {
  margin-top: 50px;
  display: flex;
  align-items: stretch;
  margin-left: -50px;
  margin-right: -50px;
}
.eset-console-item {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}
.eset-console-item__pre-title {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  display: inline-block;
  text-align: center;
  width: 150px;
  border-radius: 3px;
  color: #fff;
}
.eset-console-item__pre-title.blue {
  background: #02a1d8;
}
.eset-console-item__pre-title.grey {
  background: #dfdfdf;
}
.eset-console-item__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  color: #56d0d4;
  margin-top: 10px;
  margin-bottom: 35px;
}
.eset-console-item__list {
  margin-bottom: 30px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 17px;
  line-height: 1.24;
  color: #424c56;
}
.eset-console-item__list-item {
  position: relative;
  padding-left: 20px;
}
.eset-console-item__list-item:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 7px;
  height: 7px;
  background: #0097a2;
  border-radius: 50%;
}
.eset-console-item__btn {
  margin-top: auto;
  width: 216px;
  border-radius: 0;
  font-size: 14px;
}
@media (max-width: 992px) {
  .eset-console__title {
    margin-left: 40px;
  }
}
@media (max-width: 768px) {
  .eset-console__holder {
    margin-left: 0;
    margin-right: 0;
    display: block;
  }
  .eset-console-item {
    margin-left: 0;
    margin-right: 0;
  }
}
.eset-details {
  padding-top: 40px;
  padding-bottom: 40px;
}
.eset-details__link {
  display: inline-block;
  width: 100%;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 18px;
  color: #4c72bc;
  text-align: center;
  cursor: pointer;
}
.eset-mdb-avards {
  padding: 120px 0;
  background: #fbfbfb;
}
.eset-mdb-avards__wrap {
  display: flex;
}
.eset-mdb-avards__avards-holder {
  flex: 4 1 50%;
  display: flex;
  justify-content: space-between;
}
.eset-mdb-avards__content {
  flex: 2 1 50%;
  margin-left: 70px;
}
.eset-mdb-avards__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 35px;
  line-height: 1.43;
  color: #424c56;
}
.eset-mdb-avards__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  color: #424c56;
  margin-top: 45px;
}
.eset-mdb-avard {
  margin: 0 35px;
}
.eset-mdb-avard__icon {
  width: 320px;
  height: 265px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center center;
}
.eset-mdb-avard__descrip {
  margin-top: 45px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: #424c56;
}
@media (max-width: 1200px) {
  .eset-mdb-avards__wrap {
    flex-direction: column;
  }
  .eset-mdb-avards__avards-holder {
    order: 2;
    margin-top: 50px;
  }
  .eset-mdb-avards__content {
    order: 1;
    margin-left: 0;
  }
  .eset-mdb-avard {
    margin-left: 0;
  }
  .eset-mdb-avard__icon {
    float: left;
    background-position: center left;
  }
}
@media (max-width: 768px) {
  .eset-mdb-avards__avards-holder {
    flex-direction: column;
  }
  .eset-mdb-avard__icon {
    width: 150px;
    height: 130px;
    background-position: top left;
  }
  .eset-mdb-avard__descrip {
    margin-top: 0;
  }
}
@media (max-width: 480px) {
  .eset-mdb-avard {
    margin-right: 0;
    margin-left: 0;
  }
  .eset-mdb-avard__icon {
    width: 200px;
    height: 180px;
    margin-right: 20px;
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}
.corp-brands {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}
.corp-brands__row {
  display: flex;
  justify-content: space-around;
}
.corp-brand {
  flex: 0 0 250px;
}
.corp-brand--separate .corp-brand__text {
  color: #b0b0b0;
}
.corp-brand__icon {
  width: 145px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center left;
}
.corp-brand__text {
  margin-top: 25px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 17px;
  line-height: 1.47;
  color: #fff;
}
.corp-brand__text strong {
  font-family: "Roboto-Bold", sans-serif;
}
@media (max-width: 1200px) {
  .corp-brand {
    flex-basis: 200px;
  }
}
@media (max-width: 992px) {
  .corp-brands {
    flex-direction: column;
  }
  .corp-brands__row {
    margin-bottom: 40px;
  }
}
@media (max-width: 520px) {
  .corp-brands__row {
    flex-direction: column;
    margin-bottom: 0;
  }
  .corp-brand {
    margin-bottom: 40px;
    flex-basis: auto;
  }
  .corp-brand__text {
    margin-top: 0px;
  }
}
.eset-quote {
  padding-top: 48px;
  padding-bottom: 48px;
}
.eset-quote__wrap {
  display: flex;
}
.eset-quote__content {
  flex: 1 0 50%;
}
.eset-quote__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 26px;
  color: #424c56;
  line-height: 1.5;
  position: relative;
}
.eset-quote__text:before,
.eset-quote__text:after {
  content: '';
  width: 20px;
  height: 15px;
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.eset-quote__text:before {
  left: 0;
  top: 10px;
  float: left;
  margin-right: 20px;
  background-image: url(../img/eset-icons/quote-up-icon.png);
}
.eset-quote__text:after {
  float: right;
  right: 0;
  bottom: -15px;
  margin-right: 20px;
  background-image: url(../img/eset-icons/quote-down-icon.png);
}
.eset-quote__subscribe {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 16px;
  color: #424c56;
  margin-top: 45px;
}
.eset-quote__bg {
  flex: 1 0 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}
@media (max-width: 768px) {
  .eset-quote__wrap {
    flex-direction: column;
  }
  .eset-quote__content {
    order: 2;
  }
  .eset-quote__bg {
    order: 1;
    height: 240px;
    background-position: center left;
    margin-bottom: 30px;
  }
}
.eset-safety {
  padding-top: 100px;
  padding-bottom: 95px;
  background-image: url(../img/bg/eset-corp-safety-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
}
.eset-safety__wrapper {
  display: flex;
}
.eset-safety__content {
  flex: 1 0 50%;
}
.eset-safety__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 35px;
  line-height: 1.43;
  color: #424c56;
  color: #fff;
}
.eset-safety__list {
  margin-top: 45px;
}
.eset-safety__list-item {
  padding-left: 30px;
  position: relative;
  margin-bottom: 40px;
}
.eset-safety__list-item:last-child {
  margin-bottom: 0;
}
.eset-safety__list-item:before {
  content: '';
  width: 7px;
  height: 7px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 10px;
  border-radius: 50%;
}
.eset-safety__iconholder {
  flex: 1 0 50%;
  background-image: url(../img/bg/30-years-ukr.png);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center center;
}
@media (max-width: 1200px) {
  .eset-safety__iconholder {
    background-size: 60%;
  }
}
@media (max-width: 992px) {
  .eset-safety__content {
    flex-basis: 60%;
  }
  .eset-safety__iconholder {
    flex-basis: 40%;
    background-size: 80%;
  }
}
@media (max-width: 670px) {
  .eset-safety__wrapper {
    flex-direction: column;
  }
  .eset-safety__content {
    order: 2;
  }
  .eset-safety__iconholder {
    order: 1;
    height: 160px;
    background-size: contain;
    background-position: center left;
    margin-bottom: 30px;
  }
}
.eset-worldwide {
  padding-top: 95px;
  padding-bottom: 95px;
  background: #ededed;
  background-image: url(../img/bg/world-map.png);
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: top right;
}
.eset-worldwide__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 35px;
  line-height: 1.43;
  color: #424c56;
}
.eset-worldwide__list-box {
  margin-top: 50px;
}
.eset-worldwide__list-title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  color: #424c56;
}
.eset-worldwide__list {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  line-height: 1.1;
}
.eset-worldwide__list-item {
  padding-left: 30px;
  position: relative;
  margin-top: 25px;
  color: #424c56;
}
.eset-worldwide__list-item:before {
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 0;
  background: #3d73ab;
  border-radius: 50%;
}
@media (max-width: 992px) {
  .eset-worldwide {
    background-size: 80%;
  }
}
@media (max-width: 768px) {
  .eset-worldwide {
    background-image: none;
  }
}
.eset-corp-solutions {
  background: #fbfbfb;
  padding-top: 95px;
  padding-bottom: 95px;
}
.eset-corp-solutions__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 35px;
  line-height: 1.43;
  color: #424c56;
  text-align: center;
}
.eset-corp-solutions__subtitle {
  text-align: center;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  color: #424c56;
  margin-top: 50px;
}
.eset-corp-solutions__inner-wrapper {
  margin-top: 95px;
}
.corp-sol-item {
  padding-left: 120px;
  position: relative;
  margin-bottom: 60px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 17px;
  line-height: 1.24;
  color: #424c56;
}
.corp-sol-item__bg {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  top: 0;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.corp-sol-item__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  color: #56d0d4;
}
.corp-sol-item__text {
  margin-top: 27px;
}
.corp-sol-item__list {
  margin-top: 30px;
}
.corp-sol-item__list-item {
  padding-left: 20px;
  position: relative;
  margin-bottom: 25px;
}
.corp-sol-item__list-item:last-child {
  margin-bottom: 0;
}
.corp-sol-item__list-item:before {
  content: '';
  width: 7px;
  height: 7px;
  display: block;
  background: #0097a2;
  position: absolute;
  top: 7px;
  left: 0;
  border-radius: 50%;
}
@media (max-width: 425px) {
  .corp-sol-item {
    padding-left: 0;
  }
  .corp-sol-item__bg {
    position: relative;
    margin-bottom: 20px;
  }
}
.eset-os {
  padding-top: 100px;
  padding-bottom: 100px;
  background: #f4f5f4;
}
.eset-os__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  color: #424d56;
  text-align: center;
  font-family: "Roboto-Regular", sans-serif;
}
.eset-os__descrip {
  color: #424d56;
  text-align: center;
  font-size: 17px;
  margin-top: 30px;
}
.eset-os__icon-holder {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.eset-os__row {
  display: flex;
  justify-content: space-around;
}
.eset-os__icon-item {
  flex: 1 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
}
.eset-os__icon {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.eset-os__icon-name {
  color: #424d56;
  font-size: 17px;
  margin-top: 60px;
  white-space: nowrap;
}
@media (max-width: 1120px) {
  .eset-os__icon-holder {
    flex-direction: column;
  }
  .eset-os__icon-name {
    margin-top: 40px;
  }
  .eset-os__row {
    margin-bottom: 50px;
  }
  .eset-os__row:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 620px) {
  .eset-os__icon-holder {
    display: block;
  }
  .eset-os__icon-name {
    margin-top: 20px;
  }
  .eset-os__row {
    display: block;
  }
  .eset-os__icon-item {
    margin-bottom: 40px;
  }
}
.bitrix-hero {
  padding-top: 80px;
  padding-bottom: 80px;
  background-image: -moz-linear-gradient(-2deg, #12bfed 0%, #67cd7a 100%);
  background-image: -webkit-linear-gradient(-2deg, #12bfed 0%, #67cd7a 100%);
  background-image: -ms-linear-gradient(-2deg, #12bfed 0%, #67cd7a 100%);
}
.bitrix-hero__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 40px;
  color: #fff;
  line-height: 1.5;
  text-align: center;
}
.bitrix-hero__descrip {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 18px;
  color: #fff;
  text-align: center;
  line-height: 1.5;
  margin-top: 40px;
}
.bitrix-versions {
  padding-top: 75px;
  padding-bottom: 75px;
}
.bitrix-versions__wrap {
  margin-left: -45px;
  margin-right: -45px;
  display: flex;
}
.bitrix-versions__btn {
  width: 313px;
  margin: 0 auto;
  margin-top: 55px;
}
.bitrix-version {
  flex: 1 0 0;
  margin-left: 45px;
  margin-right: 45px;
}
.bitrix-version__icon {
  width: 100px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.bitrix-version__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  color: #333333;
  margin-top: 30px;
}
.bitrix-version__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 30px;
}
@media (max-width: 630px) {
  .bitrix-versions__wrap {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .bitrix-version {
    margin-bottom: 50px;
    margin-left: 0;
    margin-right: 0;
  }
  .bitrix-version:last-child {
    margin-bottom: 0;
  }
}
.bitrix-section-heading {
  text-align: center;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 26px;
  color: #333333;
  margin-bottom: 160px;
}
.bitrix-section {
  padding-top: 100px;
  padding-bottom: 100px;
  border-bottom: 1px solid #e8e8e8;
}
.bitrix-section--no-border {
  border-bottom: 0;
}
.bitrix-section:last-child {
  border-bottom: 0;
}
.bitrix-section__wrapper {
  display: flex;
  align-items: stretch;
  margin-left: -30px;
  margin-right: -30px;
}
.bitrix-section__content {
  flex: 1 0 0;
  margin-left: 30px;
  margin-right: 30px;
}
.bitrix-section__title {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 26px;
  color: #333333;
  margin-bottom: 35px;
}
.bitrix-section__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: #333333;
  margin-bottom: 35px;
}
.bitrix-section__text:last-child {
  margin-bottom: 0;
}
.bitrix-section__list-item {
  position: relative;
  padding-left: 27px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: #333333;
}
.bitrix-section__list-item:before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #c0ebff;
  position: absolute;
  left: 0;
  top: 10px;
}
.bitrix-section__bg {
  margin-left: 30px;
  margin-right: 30px;
  flex: 1 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
@media (max-width: 992px) {
  .bitrix-section__wrapper {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .bitrix-section__content {
    order: 2;
    margin-left: 0;
    margin-right: 0;
  }
  .bitrix-section__bg {
    height: 350px;
    order: 1;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    flex-basis: 350px;
    background-size: contain;
    background-position: center left;
  }
}
.bitrix-compare {
  padding-top: 50px;
  padding-bottom: 130px;
}
.bitrix-compare__title {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 26px;
  color: #333333;
  text-align: center;
}
.bitrix-compare__btn {
  width: 313px;
  margin: 0 auto;
  margin-top: 50px;
}
.bitrix-product {
  padding-top: 75px;
  padding-bottom: 75px;
  border-bottom: 1px solid #e8e8e8;
}
.bitrix-product--no-border-bottom {
  border-bottom: none;
}
.bitrix-product__title {
  font-family: "Roboto-Light", sans-serif;
  font-size: 36px;
  color: #333333;
  line-height: 1.08;
  margin-bottom: 30px;
}
.bitrix-tariff {
  padding-top: 55px;
  padding-bottom: 55px;
  padding-left: 50px;
  padding-right: 80px;
  border: 1px solid #e8e8e8;
  border-bottom: 0;
  display: flex;
  align-items: stretch;
}
.bitrix-tariff:last-child {
  border-bottom: 1px solid #e8e8e8;
}
.bitrix-tariff__content {
  margin-right: 20px;
  flex: 1 0 63%;
  display: flex;
  flex-direction: column;
}
.bitrix-tariff__title {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 26px;
  color: #23527c;
}
.bitrix-tariff__text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  color: #333;
  line-height: 1.71;
  margin-top: 35px;
  margin-bottom: 30px;
}
.bitrix-tariff__details-btn {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 16px;
  color: #23527c;
  background: #f8f8f8;
  width: 205px;
  margin-top: auto;
}
.bitrix-tariff__details-btn:hover,
.bitrix-tariff__details-btn:active,
.bitrix-tariff__details-btn:focus {
  color: #fff;
  background: #dfdfdf;
}
.bitrix-tariff__price-holder {
  flex: 1 0 37%;
}
.bitrix-tariff__price-text {
  font-family: "Roboto-Light", sans-serif;
  font-size: 18px;
  color: #333;
}
.bitrix-tariff__price-text.text-center {
  text-align: center;
}
.bitrix-tariff__condition-holder {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.bitrix-tariff__condition {
  display: block;
  font-family: "Roboto-Light", sans-serif;
  font-size: 14px;
  color: #333;
  padding-top: 7px;
  padding-bottom: 7px;
  text-align: center;
  width: 65px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 50px;
  margin-bottom: 10px;
  margin-right: 3.5%;
}
.bitrix-tariff__condition:last-child {
  margin-right: 0;
}
.bitrix-tariff__condition:hover,
.bitrix-tariff__condition:active,
.bitrix-tariff__condition:focus,
.bitrix-tariff__condition.active {
  border: 1px solid #c0ebff;
}
.bitrix-tariff__price {
  font-family: "Roboto-Light", sans-serif;
  font-size: 36px;
  color: #333;
  margin-top: 30px;
  text-align: center;
}
.bitrix-tariff__buy-btn {
  width: 205px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
.bitrix-feature-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.bitrix-feature-section__btn {
  width: 313px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .bitrix-tariff {
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: column;
  }
  .bitrix-tariff__price-holder {
    margin-top: 30px;
  }
  .bitrix-tariff__price {
    text-align: left;
  }
  .bitrix-tariff__buy-btn {
    margin-left: 0;
    margin-right: 0;
  }
}
