.full-descrip {
	display: none;
	&.is-visible {
		display: block;
	}
	&__wrapper {
		background: #fff;
		padding: 75px 118px;
	}
	&__text {
		margin-bottom: 35px;
		strong {
			font-family: "Roboto-Bold", sans-serif;
		}
	}
	&__heading {
		.section-titling;
		line-height: 1.3;
		margin-bottom: 35px;
	}
	&__subheading {
		font-family: "Roboto-Bold", sans-serif;
		line-height: 1;
		margin-bottom: 35px;
	}
	&__list-item {
		// text-indent: 35px;
		
		&:before {
			content: '';
			display: inline-block;
			margin-right: 35px;
			width: 10px;
			height: 10px;
			background: #f8e61e;
			border-radius: 50%;
		}
	}

	ol {
		padding-left: 18px;
	}

	.mb35 {
		margin-bottom: 35px;
	}
}