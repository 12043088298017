.corp-brands {
	margin-top: 80px;
	display: flex;
	justify-content: space-between;

	&__row {
		display: flex;
		justify-content: space-around;
	}
}

.corp-brand {
	flex: 0 0 250px;

	&--separate {

		.corp-brand__text {
			color: #b0b0b0;
		}
	}
	&__icon {
		width: 145px;
		height: 50px;

		background-repeat: no-repeat;
		background-size: contain;
		background-position: center left;
	}
	&__text {
		margin-top: 25px;
		font-family: "Roboto-Regular", sans-serif;
		font-size: 17px;
		line-height: 1.47;
		color: #fff;

		strong {
			font-family: "Roboto-Bold", sans-serif;
		}
	}
}


@media (max-width: 1200px) {
	.corp-brand {
		flex-basis: 200px
	}
}


@media (max-width: 992px) {
	.corp-brands {
		flex-direction: column;
		&__row {
			margin-bottom: 40px;
		}
	}
}

@media (max-width: 520px) {
	.corp-brands {
		
		&__row {
			flex-direction: column;
			margin-bottom: 0;
		}
	}

	.corp-brand {
		margin-bottom: 40px;
		flex-basis: auto;
		&__text {
			margin-top: 0px;
		}
	}
}