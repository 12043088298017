.eset-safety {
	padding-top: 100px;
	padding-bottom: 95px;
	background-image: url(../img/bg/eset-corp-safety-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	color: #fff;

	&__wrapper {
		display: flex;
	}
	&__content {
		flex: 1 0 50%;
	}
	&__title {
		.eset-section-titling;
		color: #fff;
	}
	&__list {
		margin-top: 45px;
	}
	&__list-item {
		padding-left: 30px;
		position: relative;
		margin-bottom: 40px;
		&:last-child {
			margin-bottom: 0;
		}
		&:before {
			content: '';
			width: 7px;
			height: 7px;
			background: #fff;
			position: absolute;
			left: 0;
			top: 10px;
			border-radius: 50%;
		}
	}
	&__iconholder {
		flex: 1 0 50%;
		background-image: url(../img/bg/30-years-ukr.png);
		background-repeat: no-repeat;
		background-size: 50%;
		background-position: center center;
		
	}
}


@media (max-width: 1200px) {
	.eset-safety {
		&__iconholder {
			background-size: 60%;
		}
	}
}

@media (max-width: 992px) {
	.eset-safety {
		&__content {
			flex-basis: 60%;
		}
		&__iconholder {
			flex-basis: 40%;
			background-size: 80%;
		}
	}
}

@media (max-width: 670px) {
	.eset-safety {
		&__wrapper {
			flex-direction: column;
		}
		&__content {
			order: 2;
		}
		&__iconholder {
			order: 1;
			height: 160px;
			background-size: contain;
			background-position: center left;
			margin-bottom: 30px;
		}
	}
}