.main-seo__content::-webkit-scrollbar {
	width: 6px;
}

.main-seo__content::-webkit-scrollbar-track {
	background: #eee;
}

.main-seo__content::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #ccc;
}


.main-seo {
	padding-top: 50px;
	padding-bottom: 50px;
	border-top: 1px solid #f8f8f8;


	&__content {
		max-height: 300px;
		overflow-y: auto;
		padding: 10px;
		border: 1px solid #ccc;
	}
	&__text-block {
		margin-top: 20px;
	}
	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 26px;
		margin-bottom: 20px;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 14px;
		color: #535353;
	}

	&__list {
		margin-left: 20px;
	}
	&__list-item {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 14px;
		color: #535353;
		position: relative;
		padding-left: 25px;
		line-height: 1.5;
		margin-bottom: 10px;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 7px;
			left: 0;
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background: #4267af;
		}
	}

}
