.eset-solutions {
	background: #fbfbfb;
	padding-top: 120px;
	padding-bottom: 150px;

	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 35px;
		color: #424c56;
		text-align: center;
		margin-bottom: 75px;
	}
}

.eset-solutions-item {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding-top: 90px;
	padding-bottom: 65px;
	padding-left: 50px;
	padding-right: 50px;
	margin-bottom: 20px;

	display: flex;
	flex-direction: column;

	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 30px;
		color: #fff;
		margin-bottom: 25px;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		line-height: 1.5;
		color: #fff;
		margin-bottom: 30px;
	}
	&__btn {
		margin-top: auto;
		width: 216px;

	}
}


@media (max-width: 1200px) {
	.eset-solutions {
		.row-flex, .row-flex > div[class*='col-'] {
			display: block;
		}
	}
	.eset-solutions-item {
		width: 100%;
		display: block;
	}
}

@media (max-width: 768px) {
	.eset-solutions {
		padding-top: 70px;
		padding-bottom: 50px;
	}
	.eset-solutions-item {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.eset-solutions-item {
		padding-left: 20px;
		padding-right: 20px;
	}
}


@media (max-width: 450px) {
	.eset-solutions-item {
		&__btn {
			width: 100%;
		}
	}
}