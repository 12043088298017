.main-news {
	padding: 100px 0;
	&__title {
		.section-titling;
		padding-bottom: 25px;
		border-bottom: 1px solid #6485ea;
	}
	&__row {
		padding: 50px 0;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		margin: 0 -25px;
		border-bottom: 1px solid #eeeeee;
	}	
}

.news-item {
	margin: 0 25px;
	flex: 1;
	overflow: hidden;

	&--flex2 {
		flex: 2;
	}

	&--picture {
		
	}
	&__lg-img {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		transition: transform .3s ease-in-out;
	}
	&__date {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 14px;
		color: #a4a4a4;
		margin-bottom: 40px;
	}
	&__title {
		color: #000;
		font-family: "Roboto-Bold", sans-serif;
		line-height: 1.5;
		padding-bottom: 25px;
		margin-bottom: 20px;
		position: relative;
		.anim-line {
			height: 2px;
			width: 0%;
			position: absolute;
			left: 0;
			bottom: 0;
			background: #4b6eb3;
			transition: width .4s ease-in-out;
			&:before {
				content: '';
				position: absolute;
				display: block;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: #4b6eb3;
				top: -2px;
				right: -6px;
			}
		}
	}
	&__text {
		font-size: 14px;
		color: #333333;
		line-height: 1.5;
	}
	&__img {
		height: 85px;
		width: 100%;
		margin-bottom: 20px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom center;
		transition: transform .3s ease-in-out;
	}

	&:hover &__img,
	&:active &__img,
	&:focus &__img, {
		transform: scale(1.05);
	}


	&:hover &__title,
	&:active &__title,
	&:focus &__title, {
		color: #6485ea;
	}

	&:hover,
	&:active,
	&:focus, {
		.anim-line {
			width: 100%;
			&:before {
				right: 0;
			}
		}
	}
	
}



@media (max-width: 992px) {
	.main-news {
		padding-bottom: 30px;
		padding-top: 30px;
		&__wrapper {
			display: flex;
			justify-content: center;
			align-items: flex-start;
		}

		&__row {
			margin: 0 20px;
			flex-direction: column;
		}
	}

	.news-item {
		order: 2;
		margin: 0;
		margin-bottom: 30px;
		&--flex2 {
			order: 1;
		}

		&__lg-img {
			width: 100%;
			padding-top: 53%;
		}

		&__img {
			width: 180px;
		}

	}
}


@media (max-width: 460px) {
	.main-news {

		&__wrapper {
			flex-wrap: wrap;
		}
		&__row {
			padding: 20px 0;
		}
	}

	.news-item {
		&__date {
			margin-bottom: 20px;
		}
	}
}