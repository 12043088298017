@media (min-width: 1600px) {
	.container {
		width: 1500px;
	}
}

@media (min-width: 1440px) {
	.container {
		width: 1350px;
	}
}

@media (min-width: 1536px) {
	.container {
		width: 1400px;
	}
}

