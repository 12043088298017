.main-programs {
	padding-top: 75px;
	border-bottom: 1px solid #f8f8f8;

	&__row {
		margin-bottom: 70px;
	}
	&__row-title {
		.section-titling;
		margin-bottom: 50px;
	}
}


.programs-item {
	display: flex;
	align-items: center;
	color: #333333;
	&__icon {
		width: 100px;
		height: 100px;
		margin-right: 8%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
	&__content {
		width: 100%;
	}
	&__name {
		margin-bottom: 20px;
		font-family: "Roboto-Bold", sans-serif;
		font-size: 16px;
		line-height: 1.31;
	}
	&__line {
		display: block;
		width: 0%;
		height: 2px;
		background: #4b6eb3;
		position: relative;
		transition: width .5s ease-out;
		&:after {
			content: '';
			display: block;
			width: 8px;
			height: 8px;
			background: #4b6eb3;
			position: absolute;
			right: -4px;
			top: -3px;
			border-radius: 50%;
		}
	}

	&:hover &__line,
	&:active &__line,
	&:focus &__line {
		width: 100%;
	}

	&:hover,
	&:active,
	&:focus, {
		color: #4b6eb3;
	}
}