.serv-hero {
	padding-top: 100px;
	padding-bottom: 160px;
	background: #6485ea;
	text-align: center;


	&__title {
		.main-titling;
	}

	&__describe {
		font-family: "Roboto-Bold", sans-serif;
		color: #fff;
		padding: 45px 0;
	}

	&__btn-white {
		margin-right: 15px;
	}


}


@media (max-width: 1200px) {
	.serv-hero {
		&__btn-holder {
			
		}

		&__btn-white, &__btn-blue {
			width: 100%;
			display: block;
			padding-top: 0;
			padding-bottom: 0;
		}

		&__btn-white {
			margin-bottom: 15px;
		}
	}
}

@media (max-width: 425px) {
	.serv-hero {
		padding: 60px 0;
		&__title {
			line-height: 1.2;
			font-size: 35px;
		}
	}
}


@media (max-width: 355px) {
	.serv-hero {
		&__btn-blue {
			line-height: 2;
		}
	}
}