.eset-mng {
	padding-top: 60px;
	padding-bottom: 60px;
	background: #fbfbfb;

	&__wrapper {
		display: flex;
		align-items: stretch;
	}
	&__content {
		flex: 1 0 50%;
	}
	&__title {
		.eset-section-titling;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		line-height: 1.5;
		margin-top: 45px;

		.inner-link {
			color: #01a1b3;
			&:hover,
			&:active,
			&:focus {
				color: darken(#01a1b3, 10%);
			}
		}
	}

	&__corp-wrapper {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		line-height: 1.5;
		color: #424c56;
		margin-top: 90px;
	}
	&__corp-list {
		margin-top: 35px;
	}
	&__corp-list-item {
		padding-left: 30px;
		position: relative;
		margin-bottom: 35px;
		&:last-child {
			margin-bottom: 0;
		}
		&:before {
			content: '';
			position: absolute;
			top: 10px;
			left: 0;
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background: #4467b0;
		}
	}
	&__corp-more-link {
		margin-left: 30px;
		color: #00abb4;
		text-decoration: underline;
		margin-top: 40px;
		display: inline-block;
		&:hover,
		&:active,
		&:focus {
			color: darken(#00abb4, 10%);
			text-decoration: underline;
		}
	}

	&__corp-btn {
		margin-top: 70px;
		width: 370px;
	}

	&__bg {
		flex: 1 0 50%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;

		&--mdb {
			background-size: contain;
			background-position: center right;
		}

		&--corp {
			background-size: 80%;
			background-position: top right;
		}
	}
}



@media (max-width: 1200px) {
	.eset-mng {
		&__content {
			flex: 3;
		}
		&__bg {
			flex: 2;
			margin-left: 50px;
		}
	}
}

@media (max-width: 768px) {
	.eset-mng {
		&__wrapper {
			flex-direction: column;
		}
		&__content {
			order: 2;
		}
		&__bg {
			margin-left: 0;
			height: 300px;
			order: 1;

			&--mdb {
				background-position: center center;
				background-size: 50%;
			}

			&--corp {
				background-position: center left;
				background-size: contain;
				margin-bottom: 30px;
			}
		}
	}
}


@media (max-width: 420px) {
	.eset-mng {
		&__corp-btn {
			width: 100%;
		}
	}
}