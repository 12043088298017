.tolider {
	&__wrapper {
		display: flex;
	}
	&__quote-holder {
		flex: 1;
	}
	&__form-holder {
		flex: 1;
	}
}

.quote-content {
	width: 62%;
	padding-top: 90px;
	padding-bottom: 65px;
	position: relative;
	&--about {
		padding-top: 0;
	}
	&--about:before {
		display: none !important;
	}
	&__img {
		width: 100%;
		height: auto;
		margin-bottom: 45px;
	}
	&__quote {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		line-height: 1.5;
		color: #000;
		margin-bottom: 30px;
	}
	&__author {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 14px;
	}

	&:before, &:after {
		content: '';
		position: absolute;
		display: block;
		width: 50px;
		height: 40px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}

	&:before {
		background-image: url(../img/icons/quote-up.png);
		left: 0;
		top: 0;
	}
	&:after {
		background-image: url(../img/icons/quote-down.png);
		right: 0;
		bottom: 0;
	}
}


.tolider-form {
	&__title {
		.section-titling;
	}
	&__wrapper {}

	&__input {
		display: block;
		width: 100%;
		font-family: "Roboto-Regular", sans-serif;
		font-size: 14px;
		padding: 20px 0;
		border: 0;
		border-bottom: 1px solid #000;
		margin-bottom: 20px;
		outline: 0;
	}

	&__submit {
		border: 0;
		width: 100%;
		margin-top: 70px;
	}
}


@media (max-width: 768px) {
	.tolider {
		&__wrapper {
			display: flex;
			flex-direction: column;
		}
	}

	.quote-content {
		margin: 0 auto;
		padding-top: 50px;
		padding-bottom: 40px;
		&:before, &:after {
			width: 30px;
			height: 24px;
		}
	}

}
