.bitrix-versions {
	padding-top: 75px;
	padding-bottom: 75px;
	&__wrap {
		margin-left: -45px;
		margin-right: -45px;
		display: flex;
	}
	&__btn {
		width: 313px;
		margin: 0 auto;
		margin-top: 55px;
	}
}

.bitrix-version {
	flex: 1 0 0;
	margin-left: 45px;
	margin-right: 45px;
	&__icon {
		width: 100px;
		height: 60px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 26px;
		color: #333333;
		margin-top: 30px;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 18px;
		line-height: 1.5;
		margin-top: 30px;
	}
}



@media (max-width: 630px) {
	.bitrix-versions {
		&__wrap {
			display: block;
			margin-left: 0;
			margin-right: 0;
		}
	}

	.bitrix-version {
		margin-bottom: 50px;
		margin-left: 0;
		margin-right: 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
}