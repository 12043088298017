.eset-details {
	padding-top: 40px;
	padding-bottom: 40px;

	&__link {
		display: inline-block;
		width: 100%;
		font-family: "Roboto-Bold", sans-serif;
		font-size: 18px;
		color: #4c72bc;
		text-align: center;
		cursor: pointer;
	}
}