.hero {
	padding-top: 115px;
	background-image: url(../img/bg/main-bg.jpg);
	background-repeat: no-repeat;
	background-position: bottom center;
	background-size: cover;

}
.hero-title {
	.main-titling;
}

.hero-btn-holder {
	white-space: nowrap;
	margin-bottom: 100px;
	margin-top: 50px;
}

.hero-btn-white {
	margin-right: 20px;
}

.hero-text {
	font-family: "Roboto Bold", sans-serif;
	font-size: 18px;
	line-height: 1.5;
	color: #fff;
}



.hero-tiles-row {
	display: flex;
	justify-content: center;
	align-items: stretch;
	margin-top: 40px;
}

.hero-tile {
	flex: 1 0 360px;

	padding: 50px;
	font-size: 25px;
	line-height: 1.6;
	border-radius: 10px;
	background: #fff;
	text-align: center;
	
	margin-right: 20px;
	&:last-child {
		margin-right: 0;
	}


	&__accent {
		color: @text-accent-blue;
	}
}

@media (max-width: 1440px) {
	.hero-tile {
		padding: 30px;
		flex-basis: 200px;
	}
}

@media (max-width: 1200px) {
	.hero-tile {
		padding: 15px;
		flex-basis: 150px;
	}
}

@media (max-width: 991px) {

	.hero-tiles-row {
		flex-direction: column;
	}

	.hero-tile {
		padding: 15px;
		flex-basis: 150px;
		margin-right: 0px;
		flex-basis: auto;
		margin-bottom: 10px;
	}
}

@media (max-width: 606px) {
	.hero-btn-holder {
		white-space: auto;
	}
	.hero-btn-white {
		margin-bottom: 20px;
	}
	.hero-btn-white, .hero-btn-blue {
		margin-right: auto;
		margin-left: auto;
		display: block;
		width: 70%;
		padding-top: 10px;
		padding-bottom: 10px;
		line-height: 2;
	}

	.hero-tile {
		font-size: 18px;
		box-shadow: 0px 2px 7px 0px #A0A0A0;
	}

}


@media (max-width: 520px) {
	.hero-title {
		line-height: 1.3;
		text-align: center;
	}
	.hero-btn-white, .hero-btn-blue {
		width: 100%;
		font-size: 14px;
		white-space: pre-wrap;
		line-height: 1.2;
	}
}
