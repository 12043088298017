.stages-title {
	.section-titling;
}

.stageitem-holder {
	position: relative;
	display: flex;
	flex-direction: column;
}


.stageitem {
	display: block;
	width: 500px;
	padding-left: 170px;
	background-repeat: no-repeat;
	background-position: top left;
	margin-bottom: 50px;
	&__num {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 26px;
		line-height: 0.8;
		color: @b-accent-blue;
	}
	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 18px;
		line-height: 1.17;
		margin: 20px 0;
	}
	&__text {
		color: #6e6e6e;
		font-size: 14px;
		line-height: 1.5;
	}
	
}

.stage-icon {
	&--folder {
		background-image: url(../img/icons/folder.png);
	}

	&--design {
		background-image: url(../img/icons/graphic-design.png);
	}

	&--edit {
		background-image: url(../img/icons/edit.png);
	}

	&--management {
		background-image: url(../img/icons/project-management.png);
	}

	&--rocket {
		background-image: url(../img/icons/rocket.png);
	}

	&--graphic {
		background-image: url(../img/icons/computer-graphic.png);
	}
}

.pos-left {
	left: 0;
	position: relative;

	
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 220px;
		height: 115px;
		top: 100%;
		left: 100%;
		transform: translateX(-30%);
		background-image: url(../img/icons/arrow-right.png);
		background-repeat: no-repeat;
		background-position: center center;
	}
}

.pos-center--no-icon {
	align-self: center;
}

.pos-center {
	align-self: center;
	position: relative;
	&--no-icon {
		&:before {
			display: none;
		}
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 239px;
		height: 136px;
		right: 100%;
		bottom: 0;
		background-image: url(../img/icons/arrow-center.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}
}

.pos-right {
	margin-left: auto;
	position: relative;
	
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 220px;
		height: 115px;
		right: 100%;
		bottom: 0;
		transform: translate(40%, 70%);
		background-image: url(../img/icons/arrow-left.png);
		background-repeat: no-repeat;
		background-position: center center;
	}
}

@media (max-width: 1600px) {
	.pos-left {

		&:before {
			transform: translateX(-100%);
		}
	}
}

@media (max-width: 1440px) {

	.pos-left {
		&:before {
			left: 50%;
			transform: translateX(-50%);
		}
	}



	.pos-right {
		&:before {
			transform: translate(80%, 70%);
		}
	}

	.pos-center {
		&:before {
			background-image: url(../img/icons/arrow-left-reverse.png);
			background-size: contain;
			width: 120px;
		}
	}
}

@media (max-width: 1200px) {
	.pos-left {
		&:before {
			left: 25%;
			transform: translateX(-50%);
		}
	}

	.pos-right {
		&:before {
			transform: translate(120%, 90%);
		}
	}
}

@media (max-width: 992px) {
	.pos-left {

		&:before {
			left: 0;
			transform: none;
		}
	}

	.pos-right {
		&:before {
			transform: translate(140%, 90%);
		}
	}

	.pos-center {
		&:before {
			
			transform: translateX(20%);
		}
	}
}

@media (max-width: 758px) {

	.stageitem-holder {
		align-items: center;
	}
	.pos-left {
		align-self: none;
		&:before {
			display: none;
		}
	}

	.pos-right {
		margin-left: 0;
		align-self: none;
		&:before {
			display: none;
		}
	}

	.pos-center {
		align-self: none;
		&:before {
			display: none;
		}
	}
}

@media (max-width: 540px) {
	.stages-title {
		line-height: 1.3;
	}
	.stageitem {
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 140px;
		background-position: top center;
		margin-bottom: 10px;
		flex-shrink: 1;
		width: 100%;
		margin-top: 30px;

		&__num, &__title {
			text-align: center;
		}
	}
}