.counting-hero {
	padding-top: 110px;
	padding-bottom: 140px;
	background-image: url(../img/bg/bookkeeping-main-bg.jpg);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;

	&__title {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 40px;
		line-height: 1.5;
		padding-top: 100px;
		padding-bottom: 100px;
		padding-right: 40px;
		padding-left: 120px;
		background-image: url(../img/bg/bookkeeping-label.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;

		.accent-text {
			color: @text-accent-blue;
		}
	}
}

@media (max-width: 1440px) {
	.counting-hero {
		&__title {
			font-size: 35px;
			padding-left: 100px;
		}
	}
}

@media (max-width: 1200px) {
	.counting-hero {
		&__title {
			font-size: 30px;
			padding-left: 70px;
		}
	}
}

@media (max-width: 630px) {
	.counting-hero {
		&__title {
			line-height: 1.3;
			font-size: 25px;
		}
	}
}

@media (max-width: 485px) {
	.counting-hero {
		
		padding: 50px 0;
		&__title {
			font-size: 18px;
		}
	}
}

@media (max-width: 387px) {
	.counting-hero {
		&__title {
			line-height: 1.2;
			font-size: 16px;
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}