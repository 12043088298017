.main-hero {
	background: #f8f8f8;
	padding-top: 100px;
	padding-bottom: 75px;

	&__tiles-holder {
		display: flex;
		margin: 0 -10px;
	}

	// &__row {
	// 	display: flex;
	// }
}

.mhero-tile {
	background: #fff;
	margin: 0 10px;
	flex: 1 0 0;
	border: 1px solid transparent;
	display: flex;
	flex-direction: column;
	color: #000;

	&:hover,
	&:active,
	&:focus, {
		color: #000;
	}

	&--simple {
		overflow: hidden;
	}


	&--special {
		background: #f8f8f8;
		transform: translateY(200px);
		position: relative;
		border: 1px solid #000;

		
		&:hover .mhero-tile__text-wrapper,
		&:active .mhero-tile__text-wrapper,
		&:focus .mhero-tile__text-wrapper {
			position: relative;
			transform: translateY(-30px);
		}

		&:hover,
		&:active,
		&:focus, {
			border: 1px solid #A8A8A8;
		}

		.top-text {
			position: absolute;
			top: -200px;
			width: 100%;
			padding-top: 80px;
			padding-left: 35px;
			padding-right: 35px;
			font-size: 14px;
			line-height: 1.5;
		}
	}

	&__bg {
		flex: auto 0 250px;
		height: 250px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		width: 100%;
		transition: transform .4s ease-out;
		&.bgsz70-per {
			background-size: 70%;
		}
	}

	&__text-wrapper {
		padding: 35px;
		flex: 2 0 auto;
		margin-top: auto;
		overflow: hidden;
		position: relative;
		transition: padding .4s ease-out, transform .4s ease-out;
	}
	&--special &__text-wrapper {
		padding-top: 5px;
		padding-bottom: 70px;
	}
	&__subtitle {
		font-size: 14px;
		line-height: 1.5;
	}
	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 20px;
		line-height: 1.35;
		margin-top: 25px;
	}

	&__arrow {
		display: block;
		width: 35px;
		height: 4px;
		background: #000;
		position: absolute;
		bottom: -50px;
		left: 35px;
		transition: bottom .4s ease-out;
		&:after {
			content: '';
			position: absolute;
			right: 0;
			top: 50%;
			width: 14px;
			height: 14px;
			border-bottom: 3px solid #000;
			border-right: 3px solid #000;
			transform: rotate(-45deg) translate(30%, -42%);
		}
	}

	&:hover &__arrow,
	&:active &__arrow,
	&:focus &__arrow {
		bottom: 35px;
	}
	&--simple:hover &__text-wrapper,
	&--simple:active &__text-wrapper,
	&--simple:focus &__text-wrapper {
		padding-top: 15px;
		padding-bottom: 55px;
	}
	&--simple:hover,
	&--simple:active,
	&--simple:focus, {
		box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.08);
	}

	&--simple:hover &__bg,
	&--simple:active &__bg,
	&--simple:focus &__bg, {
		transform: scale(1.05)
	}


}



.attantion-block {
	display: flex;
	align-items: center;
	margin-top: 75px;

	&__icon {
		flex-shrink: 0;
		width: 100px;
		height: 100px;
		margin-right: 20px;
		background: #fff;
		font-family: "Roboto-Black", sans-serif;
		font-size: 57px;
		line-height: 100px;
		text-align: center;
		color: #ff0000;
	}

	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 20px;
		margin-bottom: 20px;
	}
	&__text {
		font-size: 14px;
	}
}


@media (max-width: 1200px) {
	.main-hero {
		&__tiles-holder {
			// flex-direction: column;
			// align-items: center;
			flex-wrap: wrap;
		}
	}

	.mhero-tile {
		flex-grow: 1;
		flex-basis: 400px;
		width: 400px;
		margin-bottom: 40px;
		&--special {
			transform: none;
			
			.top-text {
				top: 20px;
			}

		}
	}

	.attantion-block {
		padding: 0 50px;
	}
}


@media (max-width: 992px) {
	.main-hero {
		&__tiles-holder {

		}
	}
	.mhero-tile {
		width: 350px;
		flex-basis: 350px;


		&__arrow {
			bottom: 35px;
		}
		&__text-wrapper {
			padding-top: 20px;
			padding-bottom: 55px;
		}
	}
}

@media (max-width: 768px) {
	.main-hero {
		&__tiles-holder {
			padding-left: 10%;
			padding-right: 10%;
			margin-left: 0;
			margin-right: 0;
		}
	}
	.mhero-tile {
		width: 80%;
		flex-basis: 80%;
	}
}

@media (max-width: 668px) {
	.main-hero {
		&__tiles-holder {
			display: block;
		}
	}

	.mhero-tile {
		width: 100%;
		height: 430px;
	}


}

@media (max-width: 660px) {
	.main-hero {

	}
}

@media (max-width: 425px) {

	.main-hero {
		&__tiles-holder {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	.mhero-tile {
		margin-left: 5px;
		margin-right: 5px;
		height: 460px;
		&__title {
			font-size: 18px;
			line-height: 1.2;
		}
	}

	.attantion-block {
		text-align: center;
		padding: 0 20px;
		flex-direction: column;

		&__icon {
			margin-bottom: 20px;
			margin-right: 0;
		}
	}
}


.slick-dots {
	margin: 0 auto;
	li {
		display: inline-block;
		margin-right: 30px;
		&:last-child {
			margin-right: 0;
		}
		button {
			display: block;
			width: 15px;
			height: 15px;
			background: #ccc;
			border-radius: 50%;
			border: 0;
			padding: 0;
			font-size: 0;

		}
	}
	
	.slick-active {
		button {
			background: #ff5858;
		}
	}
	
}