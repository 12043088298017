.demo {
	display: none;
	&.is-visible {
		display: block;
	}
	&__wrapper {
		background: #fff;
		padding: 20px;
		padding-bottom: 70px;
	}
	&__topline {
		border-bottom: 1px solid #e8e8e8;
	}
	&__title {
		.section-titling;
		line-height: 1.5;
		margin-left: 100px;
		padding-top: 70px;
		padding-bottom: 40px;
	}

	&__content {
		margin-left: 100px;
		padding-top: 55px;
	}
	&__text {
		margin-bottom: 35px;
		font-family: "Roboto-Regular", sans-serif;
	}
	&__subtitle {
		font-family: "Roboto-Bold", sans-serif;
		margin-bottom: 30px;
	}
	&__features {
		padding: 65px 0;
	}
	&__features-item {}

	&__btn {
		.btn-yellow;
		width: 256px;
		margin: 0 auto;
		margin-top: 70px;
	}
}

.demo-feature {
	display: flex;
	justify-content: flex-start;
	margin-right: 215px;
	margin-left: 150px;
	margin-bottom: 65px;
	&:last-child {
		margin-bottom: 0;
	}
	&__icon {
		flex-shrink: 0;
		width: 85px;
		height: 85px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		margin-right: 40px;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 12px;
		line-height: 1.5;
	}
}


@media (max-width: 1440px) {
	.demo-feature {
		margin-right: 100px;
		margin-left: 100px;
	}
}

@media (max-width: 1200px) {
	.demo-feature {
		margin-right: 10px;
		margin-left: 10px;
	}
}


@media (max-width: 992px) {
	.demo {
		&__title {
			margin-left: 50px;
		}
		&__content {
			margin-left: 50px;
		}
	}
	.demo-feature {

	}
}

@media (max-width: 550px) {
	.demo {
		&__title {
			margin-left: 0;
		}
		&__content {
			margin-left: 0;
		}
	}
	.demo-feature {
		&__icon {
			width: 50px;
			height: 50px;
			margin-right: 20px;
		}
	}
}


@media (max-width: 375px) {
	.demo {
		
	}
	.demo-feature {
		flex-direction: column;
		// align-items: center;
		&__icon {
			width: 70px;
			height: 70px;
			margin-right: 0;
			margin-bottom: 20px;
		}
	}
}