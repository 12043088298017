//castom bootstrap - 5 columns
.col-xs-5th,
.col-sm-5th,
.col-md-5th,
.col-lg-5th {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}
 
.col-xs-5th {
	width: 20%;
	float: left;
}
 
@media (min-width: 768px) {
	.col-sm-5th {
		width: 20%;
		float: left;
	}
}
 
@media (min-width: 992px) {
	.col-md-5th {
		width: 20%;
		float: left;
	}
}
 
@media (min-width: 1200px) {
	.col-lg-5th {
		width: 20%;
		float: left;
	}
}

.row-no-padding {
	[class*="col-"] {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.col-no-padding {
	padding: 0;
}


.row-flex, .row-flex > div[class*='col-'] {
    display: flex;
    flex:1 1 auto;
}

.row-flex-wrap {
	-webkit-flex-flow: row wrap;
    align-content: flex-start;
    flex:0;
}

.row-flex > div[class*='col-'] {
	 margin:-.2px; /* hack adjust for wrapping */
}