.enterprise-info {
	padding-top: 130px;
	padding-bottom: 130px;

	background: url(../img/bg/laptop-bg.jpg) top right no-repeat,
				url(../img/bg/plant-bg.jpg) top left no-repeat;
	
}
.enterprise-info-title {
	.section-titling;
}
.info-text {
	margin-top: 45px;
	&--bold {
		font-family: "Roboto-Bold", sans-serif;
	}
}

@media (max-width: 1500px) {
	.enterprise-info {
		background: url(../img/bg/laptop-bg.jpg) top right -20% no-repeat, 
	}
}

@media (max-width: 1200px) {
	.enterprise-info {
		background: url(../img/bg/laptop-bg.jpg) top right -100% no-repeat, 
	}
}

@media (max-width: 992px) {
	.enterprise-info {
		background-image: none; 
		padding-top: 70px;
	}
}

@media (max-width: 735px) {
	.enterprise-info {
		padding-bottom: 0;
	}
}

@media (max-width: 390px) {
	.enterprise-info-title {
		line-height: 1.3;
	}
	.info-text {
		margin-top: 20px;
	}
}