.breadcrumbs {
	font-family: "Roboto-Regular", sans-serif;
	&__item {
		display: inline-block;
	
		a {
			color: #fff;
			
			&:hover,
			&:active,
			&:focus {
				text-decoration: underline;
			}
		}
		&:after {
			content: '/';
			color: #fff;
			margin-left: 5px;
		}
		&:last-child:after {
			content: '';
		}
	}
}
