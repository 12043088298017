.enterprise-special {
	background-image: url(../img/bg/desktop-bg.jpg);
	background-repeat: no-repeat;
	background-position: center right;
	background-size: contain;
}

.enterprise-special-text {
	margin-bottom: 85px;
	line-height: 1.5;
}

@media (max-width: 735px) {
	.enterprise-special-text {
		margin-bottom: 20px;
	}
}