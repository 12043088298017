.eset-try {
	padding: 60px 0;
	background-image: url(../img/bg/eset-try-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	text-align: center;
	color: #fff;
	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 35px;
		margin-bottom: 35px;
		color: #fff;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		line-height: 1.5;
		margin-bottom: 35px;
	}
	&__btn {
		max-width: 313px;
		margin: 0 auto;
	}
	
}