.bitrix-hero {
	padding-top: 80px;
	padding-bottom: 80px;

	background-image: -moz-linear-gradient( -2deg, rgb(18,191,237) 0%, rgb(103,205,122) 100%);
	background-image: -webkit-linear-gradient( -2deg, rgb(18,191,237) 0%, rgb(103,205,122) 100%);
	background-image: -ms-linear-gradient( -2deg, rgb(18,191,237) 0%, rgb(103,205,122) 100%);
	&__title {
		.bitrix-titling;
	}
	&__descrip {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 18px;
		color: #fff;
		text-align: center;
		line-height: 1.5;
		margin-top: 40px;
	}
}

