.advantages {
	position: relative;
	&__bg {
		background-image: url(../img/bg/mob1.png);
		background-repeat: no-repeat;
		background-position: left top;
		background-size: contain;
		width: 183px;
		height: 600px;

		position: absolute;
		top: -40px;
		left: 0;
	}
	&__top-line {
		background: #f8e114;
		width: 100%;
	}

	&__top-title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 26px;
		line-height: 2.3;
		color: #000;
	}


	&__mark-text {
		background: #8fa7ef;
		color: #fff;
		padding: 20px 40px;
		font-family: "Roboto-Bold", sans-serif;
		font-size: 24px;
		line-height: 1.2;
		margin-top: 60px;
		border-radius: 10px;
	}

	&__list {
		margin-left: 60px;
	}

	&__list-item {
		line-height: 4;
		list-style-type: none;
		padding-left: 25px;
		position: relative;
		
		&:before {
			content: '';
			position: absolute;
			display: block;
			width: 10px;
			height: 10px;
			background: #6786e6;
			border-radius: 50%;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__simple-text {
		margin-top: 90px;
	}
}


@media (max-width: 1440px) {
	.advantages {
		&__top-title {
			line-height: 1.5;
		}
	}
}

@media (max-width: 1200px) {
	.advantages {
		&__list {
			margin-top: 20px;
		}
		&__list-item {
			line-height: 2;
			&:before {
				top: 18px;
			}
		}
	}
}

@media (max-width: 876px) {
	.advantages {
		&__bg {
			background-image: none;
		}
	}
}

@media (max-width: 600px) {
	.advantages {
		&__top-title {
			font-size: 24px;
		}

		&__mark-text {
			font-size: 20px;
		}
	}
}


@media (max-width: 425px) {
	.advantages {
		&__list {
			margin-left: 30px;
		}
		&__list-item {
			line-height: 1.5;
		}

		&__mark-text {
			font-size: 20px;
			margin-top: 40px;
			padding: 20px;
		}

		&__simple-text {
			margin-top: 40px;
		}
	}
}