.eset-console {
	padding-top: 50px;
	padding-bottom: 50px;
	border-top: 1px solid #969696;
	border-bottom: 1px solid #969696;
	margin-bottom: 40px;

	&__title {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 26px;
		color: #424c56;
		position: relative;
		&:before {
			content: '-';
			position: absolute;
			color: #56d0d4;
			left: -55px;
		}
	}
	&__holder {
		margin-top: 50px;
		display: flex;
		align-items: stretch;
		margin-left: -50px;
		margin-right: -50px;
	}
}

.eset-console-item {
	margin-left: 50px;
	margin-right: 50px;
	margin-bottom: 50px;

	display: flex;
	flex-direction: column;


	&__pre-title {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 12px;
		padding-top: 7px;
		padding-bottom: 7px;
		display: inline-block;
		text-align: center;
		width: 150px;
		border-radius: 3px;
		color: #fff;

		&.blue {
			background: #02a1d8;
		}

		&.grey {
			background: #dfdfdf;
		}
	}
	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 26px;
		color: #56d0d4;
		margin-top: 10px;
		margin-bottom: 35px;
	}
	&__list {

		// margin-top: auto;
		margin-bottom: 30px;
		font-family: "Roboto-Regular", sans-serif;
		font-size: 17px;
		line-height: 1.24;
		color: #424c56;
		
	}
	&__list-item {
		position: relative;
		padding-left: 20px;
		&:before {
			content: '';
			position: absolute;
			top: 5px;
			left: 0;
			width: 7px;
			height: 7px;
			background: #0097a2;
			border-radius: 50%;
		}

	}
	&__btn {
		margin-top: auto;
		width: 216px;
		border-radius: 0;
		font-size: 14px;
	}

}


@media (max-width: 992px) {
	.eset-console {
		&__title {
			margin-left: 40px;
			&:before {

			}
		}
	}
	
}


@media (max-width: 768px) {

	.eset-console {
		&__holder {
			margin-left: 0;
			margin-right: 0;
			display: block;
		}
	}

	.eset-console-item {
		margin-left: 0;
		margin-right: 0;
	}
	
}