.eset-corp-solutions {
	background: #fbfbfb;
	padding-top: 95px;
	padding-bottom: 95px;


	&__title {
		.eset-section-titling;
		color: #424c56;
		text-align: center;
	}
	&__subtitle {
		text-align: center;
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		color: #424c56;
		margin-top: 50px;
	}
	&__inner-wrapper {
		margin-top: 95px;
	}
	
}


.corp-sol-item {
	padding-left: 120px;
	position: relative;
	margin-bottom: 60px;

	font-family: "Roboto-Regular", sans-serif;
	font-size: 17px;
	line-height: 1.24;
	color: #424c56;

	&__bg {
		width: 100px;
		height: 100px;
		position: absolute;
		left: 0;
		top: 0;
		margin-right: 20px;

		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 26px;
		color: #56d0d4;

	}
	&__text {
		margin-top: 27px;
	}
	&__list {
		margin-top: 30px;
	}
	&__list-item {
		padding-left: 20px;
		position: relative;
		margin-bottom: 25px;
		&:last-child {
			margin-bottom: 0;
		}
		&:before {
			content: '';
			width: 7px;
			height: 7px;
			display: block;
			background: #0097a2;
			position: absolute;
			top: 7px;
			left: 0;
			border-radius: 50%;
		}
	}
}



@media (max-width: 425px) {
	.corp-sol-item {
		padding-left: 0;
		&__bg {
			position: relative;
			margin-bottom: 20px;
		}
	}
}