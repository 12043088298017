.about-blueline {
	font-family: "Roboto-Regular", sans-serif;
	padding-top: 75px;
	padding-bottom: 75px;
	background: #6485ea;
	&__text {
		color: #fff;
		margin-bottom: 35px;
		.underlined {
			font-family: "Roboto-Bold", sans-serif;
			display: inline-block;
			border-bottom: 2px solid #f8e304;
		}
		&:last-child {
			margin-bottom: 0;
		}

	}
}
