.eset-hero {
	padding-top: 50px;
	padding-bottom: 100px;
	background-image: url(../img/bg/eset-hero-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	
	&--smb {
		background-image: url(../img/bg/eset-smb-hero-bg.jpg);
	}

	&--mdb {
		background-image: url(../img/bg/eset-mdb-hero-bg.jpg);
	}

	&--corpor {
		background-image: url(../img/bg/eset-corpor-hero-bg.jpg);
	}

	&__content {
		margin-top: 60px;
	}
	&__pre-title {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		text-align: center;
		color: #fff;
		margin-bottom: 35px;
	}
	&__title {
		.eset-title;
		margin-bottom: 60px;
	}
	&__description {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 26px;
		line-height: 1;
		color: #fff;
		text-align: center;

		strong {
			font-family: "Roboto-Bold", sans-serif;
		}
	}
	&__btn {
		.eset-btn;
		width: 315px;
		margin: 0 auto;
		margin-top: 80px;
	}
	&__link-holder {
		margin-top: 100px;
		display: flex;
		justify-content: center;
		color: #fff;
	}
	&__intro {
		font-family: "Roboto-Regular", sans-serif;
		display: inline-block;
		margin-right: 15px;
	}
	&__link {
		font-family: "Roboto-Bold", sans-serif;
		display: inline-block;
		margin-left: 15px;
		margin-right: 15px;
		color: #fff;
		text-decoration: underline;
		&:hover,
		&:active,
		&:focus {
			color: #8ac649;
			text-decoration: underline;
		}
	}
}

.eset-greyline {
	padding-top: 40px;
	padding-bottom: 40px;
	background: #f4f5f4;
	&__text {
		text-align: center;
	}
}




@media (max-width: 750px) {
	.eset-hero {

		&__title {
			font-size: 40px;
		}
		&__description {
			font-size: 22px;
			line-height: 1.3;
		}
		&__link-holder {
			flex-direction: column;
			align-items: center;
		}
	}
}

@media (max-width: 375px) {
	.eset-hero {
		&__btn {
			width: 100%;
		}
	}
}