.eset-worldwide {
	padding-top: 95px;
	padding-bottom: 95px;
	background: #ededed;
	background-image: url(../img/bg/world-map.png);
	background-repeat: no-repeat;
	background-size: 70%;
	background-position: top right;

	&__title {
		.eset-section-titling;
	}
	&__list-box {
		margin-top: 50px;
	}
	&__list-title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 26px;
		color: #424c56;
	}
	&__list {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		line-height: 1.1;
	}
	&__list-item {
		padding-left: 30px;
		position: relative;
		margin-top: 25px;
		color: #424c56;

		&:before {
			content: '';
			width: 7px;
			height: 7px;
			position: absolute;
			top: 10px;
			left: 0;
			background: #3d73ab;
			border-radius: 50%;
		}
	}
}


@media (max-width: 992px) {
	.eset-worldwide {
		background-size: 80%;
	}
}

@media (max-width: 768px) {
	.eset-worldwide {
		background-image: none;
	}
}