.license-desc {
	display: none;
	&.is-visible {
		display: block;
	}
	&__wrapper {
		background: #fff;
		padding: 0 20px;
	}
	&__topline {
		padding: 75px 0;
		padding-left: 100px;
	}
	&__title {
		.section-titling;
		line-height: 1.3;
	}
	&__list-holder {}
	&__list-item {
		
	}
}

.license-item {
	padding: 40px 0;
	padding-left: 100px;
	padding-right: 90px;
	border-top: 1px solid #e8e8e8;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	&__desc {
		flex-shrink: 1;
		display: inline-block;
	}
	&__inner-wrapper {
		margin-left: auto;
		display: flex;
		align-items: center;
		flex-shrink: 0;
	}
	&__price {
		font-family: "Roboto-Bold", sans-serif;
		color: #6485ea;
		font-size: 26px;
		margin-left: auto;
	}
	&__btn-buy {
		.btn-yellow;
		margin-left: 40px;

	}
}


@media (max-width: 1440px) {
	.license-item {
		// flex-wrap: wrap;
		&__desc {}
		&__price {}
		&__btn-buy {
			
		}
	}
}

@media (max-width: 1200px) {
	
	.license-item {
		// flex-wrap: wrap;
		padding-right: 0;
		&__desc {
			padding-right: 20px;
		}
		&__price {}
		&__btn-buy {
			
		}
	}
}

@media (max-width: 992px) {
	.license-desc {
		&__topline {
			padding-left: 0;
		}
	}
	.license-item {
		// flex-wrap: wrap;
		flex-direction: column;
		padding-left: 0;
		&__desc {
		}
		&__inner-wrapper {
			align-items: flex-start;
			// width: 100%;
			margin: 0;
			margin-top: 20px;
			justify-content: flex-end;
			align-items: center;
		}
		&__price {
			margin-left: 0;
		}
		&__btn-buy {
			line-height: 3.5;
		}
	}
}


@media (max-width: 450px) {
	
	.license-item {
		&__desc {
		}
		&__price {
			font-size: 20px;
		}
		&__btn-buy {}
	}
}