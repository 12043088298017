.serv-content {
	padding-top: 90px;

	&__text {
		margin-bottom: 20px;
	}

	&__text-bordered {
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
		padding: 40px 25px;
		border-radius: 10px;
		padding-left: 120px;
		position: relative;
		&:before {
			content: '';
			width: 60px;
			height: 2px;
			background: #6485ea;
			display: block;
			position: absolute;
			left: 20px;
			top: 40px;
			margin-top: 10px;
		}

		.accent-text {
			color: #6485ea;
		}
	}
}

@media (max-width: 520px) {
	.serv-content {

		&__text-bordered {
			padding-left: 60px;
			&:before {
				width: 20px;
			}
		}
	}
}


