.sticker {
	width: 1000px;
	position: relative;
	line-height: 1.5;
	&--top {
		margin-top: 100px;
		margin-bottom: 50px;
	}

	&__text {
		font-family: "Roboto Bold", sans-serif;
		color: #fff;
		background: url(../img/bg/sticker.png) no-repeat top center;

		height: 473px;
		width: 641px;
		padding-top: 140px;
		padding-bottom: 180px;
		padding-right: 80px;
		padding-left: 80px;
	}
	&__subtext {
		color: #262626;
		font-family: "Roboto Bold", sans-serif;
		background: url(../img/bg/sticker-white.png) no-repeat top center;
		width: 532px;
		height: 302px;
		position: absolute;
		right: 0;
		bottom: 0;
		padding-top: 140px;
		padding-bottom: 90px;
		padding-left: 100px;
		padding-right: 75px;
	}

	&--top &__subtext {
		margin-top: -180px;
		top: 0;
	}
	
}

@media (max-width: 1200px) {
	.sticker {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.sticker {
		&__text {
			width: 60%;
			background-size: contain;
			padding-top: 50px;

		}
		&--top &__text {
			padding-bottom: 100px;
			height: auto;
		}
		&__subtext {
			width: 60%;
			background-size: contain;
			padding-top: 100px;
		}
	}
}

@media (max-width: 735px) {
	.sticker {
		background: @b-accent-blue;
		border-radius: 10px;
		&__text, {
			background-image: none;
			padding: 20px;
			height: auto;
			// color: #262626;
			color: #fff;
			width: 100%;


		}

		&--top &__text {
			padding: 20px;
		}
		&__subtext {
			padding: 20px;
			background-image: none;
			position: relative;
			height: auto;
			width: 100%;
			display: block;
			color: #fff;
		}

		&--top &__subtext {
			margin: 0;
		}
	}
}