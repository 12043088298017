.eset-features {
	&__inner-row {
		border-bottom: 3px solid #fbfbfb;
		&:last-child {
			border-bottom: 0;
		}
	}
}

.eset-feature {
	padding-top: 60px;
	padding-bottom: 60px;
	display: flex;
	align-items: center;

	&__icon {
		width: 100px;
		height: 100px;
		margin-right: 60px;

		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 26px;
		color: #424c56;
		width: 300px;
	}
	&__more-link {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 26px;
		color: #56d0d4;
		cursor: pointer;
		margin-left: auto;
		position: relative;
		&:after {
			content: '+';
			position: absolute;
			left: 100%;
			margin-left: 30px;

		}
		&.open {
			&:after {
				content: '-'
			}
		}
		&:hover,
		&:active,
		&:focus {
			color: darken(#56d0d4, 10%);
		}
	}
}

.f-droplist {
	display: none;
	&.open {
		display: block;
	}
}

.f-droplist-item {
	padding-top: 50px;
	padding-bottom: 50px;
	position: relative;
	background: #fbfbfb;
	border-bottom: 1px solid #969696;

	&__title {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 26px;
		color: #424c56;
		cursor: pointer;
		display: inline-block;

		&:before {
			content: '+';
			position: absolute;
			right: 100%;
			margin-right: 55px;
			font-family: "Roboto-Bold", sans-serif;
			font-size: 26px;
			color: #56d0d4;
		}

		&.open {
			&:before {
				content: '-'
			}
		}
	}
	
}

.f-subdrop {
	margin-top: 50px;
	display: none;
	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 26px;
		color: #56d0d4;
	}

	&__descrip {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 17px;
		line-height: 1.24;
		width: 200px;
		margin-top: 30px;
		margin-bottom: 30px;
	}

	&__btn {
		width: 200px;
		font-size: 14px;
		border-radius: 0;
	}
}


@media (max-width: 992px) {
	.eset-feature {
		&__more-link {
			margin-right: 50px;
		}
	}

	.f-droplist-item {
		&__title {
			margin-left: 30px;
			padding-left: 20px;
			&:before {
				margin-right: 0;
				right: auto;
				left: 20px;
			}
		}
	}
}


@media (max-width: 670px) {
	.eset-feature {
		&__icon {
			margin-right: 20px;
		}
		&__title {
			font-size: 22px;
		}
		&__more-link {
			font-size: 22px;
		}
	}
}

@media (max-width: 560px) {
	.eset-feature {
		flex-wrap: wrap;
		justify-content: center;
		&__icon {
			flex-shrink: 0;
			margin-right: 0;
		}
		&__title {
			text-align: center;
			margin-top: 20px;
			margin-bottom: 20px;
		}
		&__more-link {
			margin-left: auto;
			margin-right: auto;
			&:after {
				margin-left: 5px;
			}
		}
	}

	.f-droplist-item {
		&__title {
			font-size: 22px;
		}
	}
}