.eset-quote {
	padding-top: 48px;
	padding-bottom: 48px;
	&__wrap {
		display: flex;
	}
	&__content {
		flex: 1 0 50%
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 26px;
		color: #424c56;
		line-height: 1.5;
		position: relative;
		&:before,
		&:after {
			content: '';
			width: 20px;
			height: 15px;
			position: relative;
			display: block;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
		}
		&:before {
			left: 0;
			top: 10px;
			float: left;
			margin-right: 20px;
			background-image: url(../img/eset-icons/quote-up-icon.png);
		}

		&:after {
			float: right;
			right: 0;
			bottom: -15px;
			margin-right: 20px;
			background-image: url(../img/eset-icons/quote-down-icon.png);
		}
	}
	&__subscribe {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 16px;
		color: #424c56;
		margin-top: 45px;
	}
	&__bg {
		flex: 1 0 50%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center right;
	}
}


@media (max-width: 768px) {
	.eset-quote {
		&__wrap {
			flex-direction: column;
		}
		&__content {
			order: 2;
		}
		&__bg {
			order: 1;
			height: 240px;
			background-position: center left;
			margin-bottom: 30px;
		}
	}
}