.helpers {
	padding-top: 120px;
	background-image: url(../img/bg/notebook-bg.jpg);
	background-repeat: no-repeat;
	background-position: top right;
}

.helpers-title {
	.section-titling;
}



.helpers-list-item {
	font-family: "Roboto-Regular", sans-serif;
	line-height: 4;
	position: relative;
	padding-left: 30px;

	&:before {
		content: '';
		display: block;
		height: 10px;
		width: 10px;
		position: absolute;
		border-radius: 50%;
		background: @b-accent-blue;
		top: 50%;
		margin-top: -5px;
		left: 0;
	}
}



@media (max-width: 1450px) {
	.helpers {
		background-position: top right 65%;
	}
}



@media (max-width: 615px) {
	.helpers-list-item {
		line-height: 3;
	}
}

@media (max-width: 550px) {
	.helpers-title {
		line-height: 1.8;
	}
	.helpers-list-item {
		line-height: 2;
	}
}
