.eset-defence {
	padding: 100px 0;

	background-image: url(../img/bg/eset-defence-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	overflow: hidden;

	&--corp {
		background-image: url(../img/bg/eset-corp-defence.jpg);
	}
	&__title {
		.eset-section-titling;
		color: #fff;
		text-align: center;
	}
	&__descrip {
		text-align: center;
		font-family: "Roboto-Regular", sans-serif;
		font-size: 20px;
		line-height: 1.5;
		color: #fff;
		margin-top: 50px;
	}
	&__item-wrapper {
		margin-top: 80px;
		display: flex;
		margin-left: -50px;
		margin-right: -50px;
	}

	&__details-btn {
		width: 250px;
		border-radius: 3px;
		margin: 0 auto;
		margin-top: 60px;
		margin-bottom: 120px;
	}
}


.defence-item {
	margin: 0 50px;
	flex: 1 0 0;
	&__icon {
		width: 100px;
		height: 100px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		&--mdb {
			background-size: 70%;
		}
	}
	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 20px;
		line-height: 1.2;
		color: #fff;
		margin-top: 45px;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 17px;
		line-height: 1.47;
		color: #fff;
		margin-top: 25px;
	}
}



@media (max-width: 992px) {
	.eset-defence {
		&__item-wrapper {
			margin-left: -25px;
			margin-right: -25px;
		}
	}
	.defence-item {
		margin-left: 25px;
		margin-right: 25px;
	}
}

@media (max-width: 768px) {
	.eset-defence {
		&__item-wrapper {
			flex-direction: column;
			margin-left: 0;
			margin-right: 0;
		}
	}
	.defence-item {
		margin-bottom: 50px;
		&__icon {
			width: 70px;
			height: 70px;
		}
		&__title {
			margin-top: 20px;
		}
	}
}