.prod-services {
	margin-bottom: 130px;
	&__title {
		.section-titling;
		margin-bottom: 50px;
	}
	&__tile-wrapper {
		margin: 0 -15px;
	}
	&__tile {
		background: #fff;
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
		height: 90px;
		padding: 35px 5px;
		text-align: center;
		width: 100%;
		margin-bottom: 30px;
		display: block;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #262626;
		line-height: 1.2;
		transition: transform .3s, color .3s, background .3s;
		&:hover,
		&:active,
		&:focus, {
			background: #6485ea;
			color: #fff;
			transform: scale(1.1);
		}
	}
}



@media (max-width: 1450px) {
	.prod-services {
		&__tile {
			font-size: 14px;
		}

	}
}

@media (max-width: 992px) {
	.prod-services {
		&__tile {
			height: 50px;
			font-size: 16px;
		}

	}
}



.free-tiles {
	margin-bottom: 120px;
	&__title {
		.section-titling;
		margin-bottom: 50px;
	}
	&__holder {
		margin: 0 -15px;
	}
	&__tile-item {
		// box-shadow: 1.763px 2.427px 15px 0px rgba(0, 0, 0, 0.1);
		background: #fff;
		margin-bottom: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		transition: transform .3s;
		cursor: default;
	}
	&__tile-item-icon {
		margin-top: 20%;
		width: 30%;
		height: 30%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;


	}
	&__tile-item-text {
		text-align: center;
		margin-top: 15%;
	}
}

@media (max-width: 1200px) {
	.free-tiles {
		&__tile-item-icon {
			&--last {
				margin-top: 20px;
				width: 100%;
			}
		}
		&__tile-item-text {
			font-size: 16px;
			&--last {
				margin-top: auto;
				margin-bottom: 20px;
			}
		}
	}
}

@media (max-width: 768px) {
	.free-tiles {
		margin-bottom: 50px;
		&__tile-item-icon {
			&--last {
				margin-top: 10%;
				width: 30%;
			}
		}
		&__tile-item-text {
			font-size: 18px;
		}
	}
}

@media (max-width: 450px) {
	.free-tiles {
		
		&__tile-item-text {
			font-size: 14px;
		}
	}
}