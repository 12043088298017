.enterprisehero-tiles-holder {
	margin-top: 45px;
}

.enterprisehero-tiles-row {

	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	-ms-align-items: stretch;
	align-items: stretch;
}

.enterprisehero-tile {
	flex: 1 1 200px;
	width: 150px;
	height: 180px;
	line-height: 1.33;
	border-radius: 10px;
	background: #fff;
	padding: 25px 30px;
	margin-right: 20px;
	box-shadow: 0px 3px 19px 1px #93a3d12e;

	display: flex;
	justify-content: center;
	align-items: flex-start;


	&:last-child {
		margin-right: 0;
	}

	&__text {
		padding-left: 40px;
		display: block;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			display: block;
			height: 10px;
			width: 10px;
			background: #f6e123;
			border-radius: 50%;
			top: 5px;
			left: 0;
		}
	}
}



@media (max-width: 1440px) {
	.enterprisehero-tile {
		font-size: 16px;
		padding: 20px;
		
		&__text {
			padding-left: 20px;
		}
	}
}

@media (max-width: 776px) {
	.enterprisehero-tiles-row {
		flex-direction: column;
	}

	.enterprisehero-tile {
		justify-content: flex-start;
		width: 100%;
		margin-bottom: 20px;
		height: auto;
		flex-basis: auto;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

