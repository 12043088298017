.main-about {
	padding-top: 100px;
	padding-bottom: 95px;
	background: #f8f8f8;

	&__inner-wrapper {
		position: relative;
		margin-bottom: 75px;
	}
	&__bg {
		background-image: url(../img/bg/main-about-bg.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		height: 100%;
		width: 45%;
		position: absolute;
		left: 0;
	}
	&__title {
		.section-titling;
		line-height: 1;
		padding-bottom: 25px;
		padding-right: 75px;
		border-bottom: 2px solid #6485ea;
		margin-bottom: 50px;
		box-sizing: border-box;
		display: inline-block;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 14px;
		line-height: 1.5;
		color: #535353;
		.link-more {
			font-family: "Roboto-Bold", sans-serif;
			text-decoration: underline;
		}
	}
	&__statistics {
		margin-top: 75px;
	}
	&__statistics-row {
		display: flex;
		justify-content: space-between;
		margin-bottom: 45px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	&__btn-holder {
		margin-top: 80px;
		display: flex;
		justify-content: space-between;
	}
	&__btn {
		width: 225px;
		display: inline-block;
		border-radius: 0;
		background: #fff;
	}
	&__work-label {
		padding-top: 50px;
		padding-bottom: 95px;
		margin-top: 30px;
		background-image: url(../img/bg/ukraine-map.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center right;
	}
	&__work-label-title {
		font-family: "Roboto-Bold", sans-serif;
		line-height: 1;
		font-size: 20px;
	}

	&__advantages {
		margin-top: 75px;
	}
	&__advantages-title {
		.section-titling;
		padding-bottom: 20px;
		border-bottom: 1px solid #6485ea;
	}
	&__advantages-holder {
		margin-top: 50px;
		display: flex;
		justify-content: center;
		margin-left: -50px;
		margin-right: -50px;
	}

	&__advantages-row {
		display: flex;
	}
	
}



.statistics-item {
	display: block;
	width: 120px;
	padding-bottom: 20px;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		display: block;
		height: 1px;
		width: 28px;
		background: #e8e8e8;
		bottom: 0;
		left: 0;
	}
	&__value {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 40px;
		color: #446ab2;
		margin-bottom: 18px;
	}
	&__descrip {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 14px;
		color: #333333;
		line-height: 1.5;
	}	


}

.main-advantage {
	margin: 0 50px;
	width: 150px;
	flex: 1 0 130px;
	&__icon {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background: #fff;
		background-repeat: no-repeat;
		background-size: 50%;
		background-position: center center;
	}
	&__descrip {
		margin-top: 30px;
		font-family: "Roboto-Regular", sans-serif;
		font-size: 14px;
		line-height: 1.5;
	}
}



@media (max-width: 1200px) {

	.main-about {
		&__advantages-holder {
			margin-left: -25px;
			margin-right: -25px;
			flex-wrap: wrap;
		}

		&__btn-holder {
			flex-direction: column;
		}

		&__btn {
			margin-bottom: 20px;
		}
	}
}

@media (max-width: 1270px) {
	
	.main-advantage {
		margin: 0 25px;
		&__icon {
			margin: 0 auto;
		}
		&__descrip {
			text-align: center;
		}
	}
}

@media (max-width: 992px) {
	
	.main-about {
		padding-bottom: 50px;
		padding-top: 50px;
		&__bg {
			background-image: none;
		}

		&__statistics-row {
			justify-content: space-around;
		}
		&__advantages-holder {
			flex-wrap: wrap;
			justify-content: center;
			margin-left: 0;
			margin-right: 0;
		}

		&__advantages-row {
			width: 100%;
			justify-content: space-around;
			margin-bottom: 30px;
		}

		&__work-label {
			text-align: center;
			background-position: center center;
		}

		&__btn-holder {
			flex-direction: row;
			justify-content: center;
		}

		&__btn {
			margin-bottom: 0;
			margin-right: 20px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
	.main-advantage {
		margin: 0;
		&__icon {
			width: 80px;
			height: 80px;
		}
	}
}

@media (max-width: 460px) {
	
	.main-about {
		padding-bottom: 40px;
		&__btn-holder {
			flex-direction: column;
		}

		&__advantages-row {
			flex-direction: column;
			align-items: center;
			margin-bottom: 0;
		}

		&__btn {
			margin: 0;
			width: 100%;
			margin-bottom: 20px;
		}
	}
	.main-advantage {
		margin-bottom: 20px;
		width: 250px;
	}
}


