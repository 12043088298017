.about-intro {
	position: relative;
	&__top-line {
		padding-top: 95px;
		padding-bottom: 95px;
	}
	&__bottom-line {
		padding-top: 95px;
		padding-bottom: 95px;
		background: #e8eaee;
	}
	&__abs-bg {
		background-image: url(../img/bg/about-side-bg.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		width: 43%;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
	}
}




@media (max-width: 768px) {
	.about-intro {
		&__abs-bg {
			display: none;
		}
	}
}