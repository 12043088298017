.about-work {
	padding-top: 95px;
	padding-bottom: 95px;
	background: #e8eaee;


	&__title {
		.section-titling;
	}
	&__stat {
		margin-top: 50px;
	}
	&__stat-row {
		display: flex;
		justify-content: space-between;
		margin-bottom: 60px;
	}
	&__stat-item {
		font-family: "Roboto-Regular", sans-serif;
		flex: 1 0 95px;
	}
	&__stat-item-value {
		color: #446ab2;
		font-size: 40px;
	}
	&__stat-item-descrip {
		color: #333;
		font-size: 14px;
		line-height: 1.5;
	}

	&__stat-btn-holder {
		display: flex;
		margin: 0 -10px;
	}
	&__stat-btn {
		.btn-white;
		line-height: 1;
		padding-left: 15px;
		padding-right: 15px;
		margin: 0 10px;
		flex: 1;
		color: #446ab3;
		background: #fff;
		border-radius: 0;
	}

	&__bg {
		width: 100%;
		background-image: url(../img/bg/ukraine-map-2.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		height: 400px;
	}
	
}



@media (max-width: 1450px) {
	.about-work {
		&__stat-btn-holder {
			flex-direction: column;
		}
		&__stat-btn {
			margin-bottom: 10px;
		}
	}
}

@media (max-width: 992px) {
	.about-work {
		&__stat-btn-holder {
			flex-direction: row;
		}
		&__stat-btn {
			margin-bottom: 0;
		}
	}
}

@media (max-width: 420px) {
	.about-work {
		&__stat-row {
			flex-direction: column;
			margin-bottom: 0;
		}
		&__stat-btn-holder {
			margin-top: 40px;
			flex-direction: column;
		}
		&__stat-btn {
			margin-bottom: 10px;
		}
	}
}