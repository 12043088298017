.label-menu {
	&__item {
		text-align: center;
		height: 60px;
		background: #f0f1f4;
		color: #8b8c8d;
		line-height: 60px;
		border-left: 1px solid #e8eaee;
		border-right: 1px solid #e8eaee;
		cursor: pointer;
		&:first-child {
			border-left: 0;
			// margin-right: 2px;
		}

		&.is-active {
			background: #fff;
			color: #262626;
		}

		&:hover,
		&:active,
		&:focus, {
			// border: 1px solid ;
		}
	}
}


@media (max-width: 1200px) {
	.label-menu {
		&__item {
			height: 80px;
			display: flex;
			line-height: 1;
			justify-content: center;
			align-items: center;
		}

	}
}


@media (max-width: 992px) {
	.label-menu {
		.md-no-padding {
			padding: 0;
			margin: 0 -10px;
		}
		.md-no-margin {
			margin: 0;
		}

		&__item {
			margin: 0 10px;
			margin-bottom: 20px;
		}

	}
}

@media (max-width: 450px) {
	.label-menu {
		&__item {
			font-size: 14px;
		}

	}
}

.no-padding {
	padding: 0;
}