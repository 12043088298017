.bitrix-section-heading {
	text-align: center;
	font-family: "Roboto-Regular", sans-serif;
	font-size: 26px;
	color: #333333;
	margin-bottom: 160px;
}


.bitrix-section {
	padding-top: 100px;
	padding-bottom: 100px;
	border-bottom: 1px solid #e8e8e8;
	&--no-border {
		border-bottom: 0;
	}
	&:last-child {
		border-bottom: 0;
	}
	&__wrapper {
		display: flex;
		align-items: stretch;
		margin-left: -30px;
		margin-right: -30px;
	}
	&__content {
		flex: 1 0 0;
		margin-left: 30px;
		margin-right: 30px;
	}
	&__title {
		.bitrix-section-titling;
		margin-bottom: 35px;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 18px;
		line-height: 1.5;
		color: #333333;
		margin-bottom: 35px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	&__list-item {
		position: relative;
		padding-left: 27px;
		font-family: "Roboto-Regular", sans-serif;
		font-size: 18px;
		line-height: 1.5;
		color: #333333;
		&:before {
			content: '';
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: #c0ebff;
			position: absolute;
			left: 0;
			top: 10px;
		}
	}
	&__bg {
		margin-left: 30px;
		margin-right: 30px;
		flex: 1 0 0;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
}



@media (max-width: 992px) {
	.bitrix-section { 
		&__wrapper {
			margin-left: 0;
			margin-right: 0;
			flex-direction: column;
			align-items: flex-start;
		}
		&__content {
			order: 2;
			margin-left: 0;
			margin-right: 0;
		}

		&__bg {
			height: 350px;
			order: 1;
			margin-left: 0;
			margin-right: 0;
			width: 100%;
			flex-basis: 350px;
			background-size: contain;
			background-position: center left;
		}
	}
}