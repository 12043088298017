.interesting {
	padding-top: 120px;
	padding-bottom: 200px;
	background-image: url(../img/bg/bottom-bg.jpg);
	background-repeat: no-repeat;
	background-position: bottom left;
	background-size: contain;
}

.interesting-title {
	.section-titling;
}

.interesting-list {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 65px;
}

.interesting-list-item {
	font-family: "Roboto-Bold", sans-serif;

}

.interesting-btn-holder {
	margin-top: 295px;
	white-space: nowrap;
	display: flex;
	justify-content: center;
	align-items: center;
}


.interesting-btn-blue {
	box-shadow: 0px 9px 18px -3px #dee4f9;
}
.interesting-btn-white {
	box-shadow: 0px 3px 24px 2px #e6e6e6;
}

.interesting-btn-blue, .interesting-btn-white {
	line-height: 1.5;
	margin: 0 10px;
}


@media (max-width: 1200px) {
	.interesting-list-item {
		font-size: 16px;
	}
}

@media (max-width: 725px) {

	.interesting {
		padding-bottom: 50px;
		padding-top: 50px;
	}
	.interesting-list {
		flex-direction: column;
	}
	.interesting-btn-holder {
		margin-top: 50px;
	}

	.interesting-title {
		text-align: center;
	}

	.interesting-list {
		margin-top: 20px;
	}
	.interesting-list-item {
		line-height: 2;
		text-align: center;
	}
}


@media (max-width: 564px) {
	.interesting-btn-holder {
		flex-direction: column;
		-ms-align-items: stretch;
		align-items: stretch;
	}

	.interesting-btn-blue, .interesting-btn-white {
		margin-bottom: 10px;
	}
	
}

@media (max-width: 383px) {
	.interesting-title {
		line-height: 1.5;
	}

	.interesting-btn-holder {
		white-space: normal;
	}
}