.jump-to {
	padding: 120px 0;

	&__title {
		.section-titling;
		margin-bottom: 30px;
		line-height: 1.4;
	}

	&__text {
		
		&--margin-top {
			margin-top: 90px;
		}
		.accent-text {
			background: #8fa7ef;
		}
	}
}


@media (max-width: 520px) {
	.jump-to {
		&__text {
			&--margin-top {
				margin-top: 50px;
			}
		}
	}
}

@media (max-width: 425px) {
	.jump-to {
		padding: 60px 0;
	}
}
@media (max-width: 375px) {
	.jump-to {
		&__title {
			line-height: 1.2;
			font-size: 20px;
		}
	}
}