.eset-os {
	padding-top: 100px;
	padding-bottom: 100px;
	background: #f4f5f4;

	&__title {
		font-family: "Roboto-Bold", sans-serif;
		font-size: 26px;
		color: #424d56;
		text-align: center;
		font-family: "Roboto-Regular", sans-serif;
	}
	&__descrip {
		color: #424d56;
		text-align: center;
		font-size: 17px;
		margin-top: 30px;
	}
	&__icon-holder {
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}
	&__row {
		display: flex;
		justify-content: space-around;
	}
	&__icon-item {
		flex: 1 0 100px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 40px;
		margin-right: 40px;
	}
	&__icon {
		width: 100px;
		height: 100px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
	&__icon-name {
		color: #424d56;
		font-size: 17px;
		margin-top: 60px;
		white-space: nowrap;
	}
}


@media (max-width: 1120px) {
	.eset-os {
		&__icon-holder {
			flex-direction: column;
		}

		&__icon-name {
			margin-top: 40px;
		}

		&__row {
			margin-bottom: 50px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

@media (max-width: 620px) {
	.eset-os {
		&__icon-holder {
			display: block;
		}

		&__icon-name {
			margin-top: 20px;
		}

		&__row {
			display: block;
		}

		&__icon-item {
			margin-bottom: 40px;
		}
	}
}