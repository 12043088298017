.bitrix-product {
	padding-top: 75px;
	padding-bottom: 75px;
	border-bottom: 1px solid #e8e8e8;
	&--no-border-bottom {
		border-bottom: none;
	}

	&__title {
		font-family: "Roboto-Light", sans-serif;
		font-size: 36px;
		color: #333333;
		line-height: 1.08;
		margin-bottom: 30px;
	}
}

.bitrix-tariff {
	padding-top: 55px;
	padding-bottom: 55px;
	padding-left: 50px;
	padding-right: 80px;
	border: 1px solid #e8e8e8;
	border-bottom: 0;
	display: flex;
	align-items: stretch;

	&:last-child {
		border-bottom: 1px solid #e8e8e8;
	}

	&__content {
		margin-right: 20px;
		flex: 1 0 63%;
		display: flex;
		flex-direction: column;
	}
	&__title {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 26px;
		color: #23527c;
	}
	&__text {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 14px;
		color: #333;
		line-height: 1.71;
		margin-top: 35px;
		margin-bottom: 30px;
	}
	&__details-btn {
		font-family: "Roboto-Regular", sans-serif;
		font-size: 16px;
		color: #23527c;
		background: #f8f8f8;
		width: 205px;
		margin-top: auto;

		&:hover,
		&:active,
		&:focus {
			color: #fff;
			background: darken(#f8f8f8, 10%);
		}
	}
	&__price-holder {
		flex: 1 0 37%;
	}
	&__price-text {
		font-family: "Roboto-Light", sans-serif;
		font-size: 18px;
		color: #333;
		&.text-center {
			text-align: center;
		}
	}
	&__condition-holder {
		display: flex;
		flex-wrap: wrap;
		margin-top: 20px;
	}
	&__condition {
		display: block;
		font-family: "Roboto-Light", sans-serif;
		font-size: 14px;
		color: #333;
		padding-top: 7px;
		padding-bottom: 7px;
		text-align: center;
		width: 65px;
		cursor: pointer;
		border: 1px solid transparent;
		border-radius: 50px;
		margin-bottom: 10px;
		
		margin-right: 3.5%;
		&:last-child {
			margin-right: 0;
		}

		&:hover,
		&:active,
		&:focus,
		&.active {
			border: 1px solid #c0ebff;
		}
	}
	&__price {
		font-family: "Roboto-Light", sans-serif;
		font-size: 36px;
		color: #333;
		margin-top: 30px;
		text-align: center;
	}
	&__buy-btn {
		width: 205px;
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
	}
}

.bitrix-feature-section {
	padding-top: 50px;
	padding-bottom: 50px;
	&__btn {
		width: 313px;
		margin: 0 auto;
	}
}

@media (max-width: 768px) {

	.bitrix-tariff {
		padding-left: 30px;
		padding-right: 30px;
		flex-direction: column;
		&__price-holder {
			margin-top: 30px;
		}
		&__price {
			text-align: left;
		}

		&__buy-btn {
			margin-left: 0;
			margin-right: 0;
		}
	}
}